import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
  AccordionSummaryProps
} from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import ReactJson from 'react-json-view';
import { frontendURL } from '../../axios';
import Button from '../CustomButtons/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LaunchIcon from '@mui/icons-material/Launch';
import Moment from 'moment';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  marginLeft: '28px',
  marginRight: '28px'
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions({ ...props }: any) {
  Moment.locale('en');
  const [expanded, setExpanded] = React.useState<string | false>();
  const { accordionData, stepSequence, liveFlow = false } = props;
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const ChildFlowButton = (prop: any) => {
    if (prop.hasOwnProperty('child_flow_id') && prop.hasOwnProperty('child_execution_id')) {
      const child_execution_link = frontendURL + "user/flows/" + prop['child_flow_id'] + "/execution/" + prop['child_execution_id'];
      const openChildExecutionTrace = () => {
        window.open(child_execution_link);
      }
      return (
        <div style={{float: 'right'}}>
          <Button onClick={openChildExecutionTrace} style={{float: 'right', marginRight: '0'}}> Child Execution Trace
          <LaunchIcon/>
          </Button>
        </div>
        )
    }
    return (<div style={{float: 'left', paddingLeft: '212px'}}> <ExpandMoreIcon/> </div>);
  }

  return (
    <div>
      {accordionData.map((prop: any, key: any) => {
        return (
          <Accordion expanded={expanded === ('panel' + key)} onChange={handleChange('panel' + key)}>
            <AccordionSummary  expandIcon={ChildFlowButton(prop)} aria-controls="panel1d-content" id="panel1d-header" >
              <Typography>{(!liveFlow) ? (prop.hasOwnProperty('name') ? prop['name'] : (key - 1)) : (stepSequence[key])}</Typography>
              {  prop.hasOwnProperty('status')? prop['status'] === "RUNNING" ? <Typography style={{float : 'right', marginLeft: 'auto', color: "green", marginRight: "30px"}}> {"Started At: "} { Moment(prop['startTime']).format('LLL')}  {"  - Status:" +prop['status']}</Typography>:null:null }
              {  prop.hasOwnProperty('status')? prop['status'] === "FINISHED" ? <Typography style={{float : 'right', marginLeft: 'auto', color: "black", marginRight: "30px"}}> {"Started At: "} { Moment(prop['startTime']).format('LLL')} {"  - Status: " +prop['status']}</Typography>:null:null }
            </AccordionSummary>
            <AccordionDetails>
              <ReactJson src={prop}  collapsed= {false} collapseStringsAfterLength= {200}/>
            </AccordionDetails>
          </Accordion>
        );
      }
      )}
    </div>
  )
}