import React, { DragEvent } from 'react';
import { Menu, MenuItem, ProSidebar } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { useHistory } from "react-router-dom";
import image from './assets/img/sidebar-2.jpg';
import axios from './axios';
// import './components/Sidebar/Sidebar.css';

const onDragStart = (event: DragEvent, nodeType: string) => {
  event.dataTransfer.setData('application/reactflow', nodeType);
  event.dataTransfer.effectAllowed = 'move';
};

const Logout = () => {
  axios.post('auth/logout/').catch(error => console.log("Failed to logout : " + error.message));
  localStorage.clear()
  window.location.assign("/");

}

const Sidebar = ({ ...props }) => {
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  }
  return (<ProSidebar rtl={false}  style={{ float: "left" }}>
    <Menu iconShape="square">
      <MenuItem><div className="description">Drag these to the pane.</div></MenuItem>
      <MenuItem> <div className="react-flow__node-default" onDragStart={(event: DragEvent) => onDragStart(event, 'default')} draggable>
        State Node
      </div></MenuItem>
      <MenuItem style={{ position: "absolute", bottom: "100px", left: "2%" }}>
        <button className="button-1" role="button" onClick={goBack}>Back</button>
      </MenuItem>
      <MenuItem style={{ position: "absolute", bottom: "0", left: "4%" }}>
        <div className="logout">
          <button className="button-66" role="button" onClick={Logout}>Logout</button>
        </div>
      </MenuItem>
    </Menu>
  </ProSidebar>)
};

export default Sidebar;
