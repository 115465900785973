import { Button, makeStyles, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import AddIcon from '@mui/icons-material/Add';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import OpenInNew from '@mui/icons-material/OpenInNew';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Drawer from '@mui/material/Drawer';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import { styled, useTheme } from '@mui/material/styles';
import React, { DragEvent, useEffect, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Box from '@mui/material/Box';

import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import ReactFlow, {
  addEdge, ArrowHeadType,
  Background, BackgroundVariant, Connection, Controls, Edge,
  ElementId, Elements, Handle, isEdge, getConnectedEdges,
  isNode, Node, OnLoadParams, Position, ReactFlowProvider, removeElements
} from 'react-flow-renderer';
import { useForm } from "react-hook-form";
import { v4 as uuid_v4 } from "uuid";
import { number } from 'yup';
import axios from './axios';
import './css/Flow.css';
import './css/Styles.css';
import TransitionEdge from './helpers/TransitionEdge';
import Sidebar from './Sidebar';
import {frontendURL, devflowsUrl} from "./axios";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const customNodeStyles = {
  background: '#ffffff',
  color: '#FFF',
  padding: 10,
  height: 17,
  width: 160,
  border: '2px solid #000000',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  verticalAlign: 'middle',
  borderRadius: 3,
};
const customNodeParentStyles = {
  background: '#ffffff',
  color: '#FFF',
  padding: 10,
  height: 'auto',
  width: 200,
  border: '2px solid #000000',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  verticalAlign: 'middle',
  borderRadius: 3,
};
const customNodeChildStyles = {
  background: '#ffffff',
  color: '#FFF',
  padding: 10,
  height: 15,
  width: 180,
  border: '2px solid #000000',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  verticalAlign: 'middle',
  borderRadius: 3,
  marginTop: "10px"
};
const CustomSpecialNodeComponent = ({ data }: { data: any }) => {
  return (
    <div style={customNodeParentStyles}>
      <Handle type="target" position={Position.Left} id="a" /*style={{ top: '30%'}}*/ style={{
        background: '#ff69b4', width: '8px',
        height: '8px', top: '30%'
      }} />
      <Handle type="target" position={Position.Top} id="b" style={{
        background: '#ff69b4', width: '8px',
        height: '8px', left: '10%'
      }} />
        <Handle type="target" position={Position.Top} id="s" style={{
        background: '#ff69b4', width: '8px',
        height: '8px', left: '50%'
      }} />
      <Handle type="target" position={Position.Top} id="z" style={{
        background: '#ff69b4', width: '8px',
        height: '8px', left: '30%'
      }} />

      <Handle
        type="source"
        position={Position.Right}
        id="c"
        style={{
          background: '#0047AB', width: '8px',
          height: '8px', top: '30%'
        }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="y"
        style={{
          background: '#0047AB', width: '8px',
          height: '8px', left: '30%'
        }}
      />
       <Handle
        type="source"
        position={Position.Bottom}
        id="q"
        style={{
          background: '#0047AB', width: '8px',
          height: '8px', left: '50%'
        }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="d"
        style={{
          background: '#0047AB', width: '8px',
          height: '8px', left: '10%'
        }}
      />
      <Handle type="source" position={Position.Left} id="e" style={{
        background: '#0047AB', width: '8px',
        height: '8px', top: '70%'
      }} />
      <Handle type="source" position={Position.Top} id="f" style={{
        background: '#0047AB', width: '8px',
        height: '8px', left: '90%'
      }} />
      <Handle type="source" position={Position.Top} id="x" style={{
        background: '#0047AB', width: '8px',
        height: '8px', left: '70%'
      }} />
      <Handle
        type="target"
        position={Position.Right}
        id="g"
        style={{
          background: '#ff69b4', width: '8px',
          height: '8px', top: '70%'
        }} />
      <Handle
        type="target"
        position={Position.Bottom}
        id="h"
        style={{
          background: '#ff69b4', width: '8px',
          height: '8px', left: '90%'
        }}
      />
      <Handle
        type="target"
        position={Position.Bottom}
        id="w"
        style={{
          background: '#ff69b4', width: '8px',
          height: '8px', left: '70%'
        }}
      />


      <div>
        <div style={{ color: 'black', fontSize: 12, textAlign: 'center', marginBottom: "4px", marginTop:"4px" }}>{data.label}</div>
        {data.taskType === "parallel-state" ?
          data.parallel.map((x: PublishedFSM, index: number) => {
            return (
              <div style={customNodeChildStyles}>
                <div style={{ color: 'black', fontSize: 11 }}>{x.name}</div>
              </div>)
          }) : null
        }
      </div>
    </div>
  );
};




const CustomNodeComponent = ({ data }: { data: any }) => {
  return (
    <div style={customNodeStyles}>
      <Handle type="target" position={Position.Left} id="a" /*style={{ top: '30%'}}*/ style={{
        background: '#ff69b4', width: '8px',
        height: '8px', top: '30%'
      }} />
      <Handle type="target" position={Position.Top} id="b" style={{
        background: '#ff69b4', width: '8px',
        height: '8px', left: '10%'
      }} />
      <Handle type="target" position={Position.Top} id="z" style={{
        background: '#ff69b4', width: '8px',
        height: '8px', left: '35%'
      }} />
      <div style={{ color: 'black', fontSize: 12 }}>{data.label}</div>
      <Handle
        type="source"
        position={Position.Right}
        id="c"
        style={{
          background: '#0047AB', width: '8px',
          height: '8px', top: '30%'
        }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="y"
        style={{
          background: '#0047AB', width: '8px',
          height: '8px', left: '35%'
        }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="d"
        style={{
          background: '#0047AB', width: '8px',
          height: '8px', left: '10%'
        }}
      />
      <Handle type="source" position={Position.Left} id="e" style={{
        background: '#0047AB', width: '8px',
        height: '8px', top: '70%'
      }} />
      <Handle type="source" position={Position.Top} id="f" style={{
        background: '#0047AB', width: '8px',
        height: '8px', left: '90%'
      }} />
      <Handle type="source" position={Position.Top} id="x" style={{
        background: '#0047AB', width: '8px',
        height: '8px', left: '65%'
      }} />
      <Handle
        type="target"
        position={Position.Right}
        id="g"
        style={{
          background: '#ff69b4', width: '8px',
          height: '8px', top: '70%'
        }} />
      <Handle
        type="target"
        position={Position.Bottom}
        id="h"
        style={{
          background: '#ff69b4', width: '8px',
          height: '8px', left: '90%'
        }}
      />
      <Handle
        type="target"
        position={Position.Bottom}
        id="w"
        style={{
          background: '#ff69b4', width: '8px',
          height: '8px', left: '65%'
        }}
      />

    </div>
  );
};

const nodeTypes = {
  special: CustomSpecialNodeComponent,
};

interface PublishedFSM {
  name: string,
  url: string
}

const initialElements = [{ id: '1', type: 'input', data: { label: 'Start' }, position: { x: 250, y: 250 }, sourcePosition: Position.Right, targetPosition: Position.Left },
{ id: '2', type: 'output', data: { label: 'End' }, position: { x: 750, y: 250 }, sourcePosition: Position.Right, targetPosition: Position.Left }];

const onDragOver = (event: DragEvent) => {
  event.preventDefault();
  event.dataTransfer.dropEffect = 'move';
};
let sid = 1;
const getId = (): ElementId => `node_${uuid_v4()}`;

const flowUrl = frontendURL+ 'user/flows/';
const devflowFlowsUrl = devflowsUrl + 'home/flows/';

const Flow = (props: any) => {
  const [chkbox, setChkBox] = useState<boolean>(false);
  const [toNodeDisplay, setToNodeDisplay] = useState<boolean>(false);
  const [toEdge, SetToEdgeDisplay] = useState<boolean>(false);
  const [toDisplay, SetToDisplay] = useState<boolean>(true);
  const [nodeFormData, setNodeFormData] = useState({ label: "", id: "", retry: "", url: "", description: "", parallel: [{ url: "", name: "" }], timeout: "" })
  const [edgeFormData, setEdgeFormData] = useState({ label: "", id: "", condition: "", type: "smoothstep", MAX_EDGE_TRANSITION: "" })
  const [open, setOpen] = React.useState(false);
  const [trigger, setTrigger] = useState<boolean>(false);
  const [value, setValue] = React.useState('');
  const [checkboxIsActive, setCheckboxIsActive] = useState<boolean>(true);
  const [parallel, setParallel] = useState<PublishedFSM[]>([]);
  const [stateflow, setStatelfow] = useState<PublishedFSM[]>([])
  const [toValue, setToValue] = React.useState('1');


  const handleChange1 = (event: React.ChangeEvent<{}>, newValue: string) => {
    setToValue(newValue);
  };
  // const handleChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setValue1(event.target.value);
  // };
  // const [stateflow, setStatelfow] = useState([{name:"u1",url:"u1"},{name:"u2",url:"u2"}])
  // const [stateflow, setStatelfow] = useState([{url: "", name: ""}])
  const handleInputChange = (e: any, index: number) => {
    const { name, value } = e.target;
    let list = [...stateflow];
    // console.log(list)
    console.log(name, value)
    if (name === "name") {
      list[index] = {
        name: value,
        url: list[index].url
      }
      // list[index].name = value;
    }
    else {
      list[index] = {
        name: list[index].name,
        url: value
      }
      // list[index].url=value;
    }
    setStatelfow(list);
  };


  const handleAddClick = () => {
    setStatelfow([...stateflow, { url: "", name: "" }]);
  };

  const handleRemoveClick = (index: number) => {
    const list = [...stateflow];
    list.splice(index, 1);
    setStatelfow(list);
  };






  const handleChangeTrigger = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };
  const handleCloseTrigger = () => {
    setTrigger(false)
  };
  const handleCloseState = () => {
    setTaskType('');
    setToNodeDisplay(false);
    setDevflowsFormActive(false);
    setActionName('');
    setActionUrl('');
    setClickedElement(undefined);
  }
  const handleCloseEdge = () => {
    SetToEdgeDisplay(false);
    setClickedElement(undefined);
  }
  useEffect(
    () => {
      setNodeFormData(nodeFormData);
      setEdgeFormData(edgeFormData);
    }, [nodeFormData, edgeFormData]
  )
  const [clickedElement, setClickedElement] = React.useState<any>();

  const onElementClick = (event: any, element: any) => {
    refreshState();
    setClickedElement(element);
    SetToEdgeDisplay(false);
    setToNodeDisplay(false);
    SetToDisplay(true);
    if (!(isNode(element) && (element.type === 'input' || element.type === 'output'))) {
      if (isNode(element)) {
        setUrl(element.data.url)
        setFlowPageUrl(element.data.url)
        setNodeFormData({ label: element.data.label, id: element.id, retry: element.data.retry, url: element.data.url, description: element.data.description, parallel: element.data.parallel, timeout: element.data.timeout })
        setStatelfow(element.data.parallel)
        setTimeoutEnabled(element.data.timeoutEnabled)
        setTimeoutState(element.data.timeoutState)
        if ('parallel' in element.data) {
        }
        else {
          setStatelfow([])
        }
        setEdgeFormData({ label: "", id: "", condition: "", type: "",MAX_EDGE_TRANSITION: "" })
        setToNodeDisplay(true);
        setTaskType(element.data.taskType);
      }
      else {
        if (element['source'] === element['target']) {
          setCheckboxIsActive(false);
        }
        else {
          setCheckboxIsActive(true);
        }
        setChkBox(element.data.default)
        setEdgeFormData({ label: element.data.label, id: element.id, condition: element.data.condition, type: element.type, MAX_EDGE_TRANSITION: element.data.MAX_EDGE_TRANSITION })
        setNodeFormData({ label: "", id: "", retry: "", url: "", description: "", parallel: [], timeout: "" })
        setStatelfow([])
        setConditionalEnabled(element.data.conditional)
        setConditionalState(element.data.conditionalState)
        SetToEdgeDisplay(true);
      }
    }
  };

  function checkFlowValidity(flowData: any) {
    var error_message = "Invalid Flow, can't Publish! ";
    const max_retry: number = 9;
    if (flowData === null)               // null check
      return false;
    const flowDataJson = JSON.parse(JSON.stringify(flowData));
    var nodeIdAsSource = new Map();      // holds true when a nodeId has output connector(is source)
    var nodeIdAsTarget = new Map();      // holds true when a nodeId has input connector(is target) 
    var nodeIdVsConditions = new Map();  // set of conditions for all edges with nodeId as source
    var defaultNodeActive = new Set();   // true when default edge is active for a node
    var input_node_count: number = 0, output_node_count: number = 0;
    var start_output: boolean = false, end_input: boolean = false;

    for (let index in flowDataJson["elements"]) {
      const elementJson = JSON.parse(JSON.stringify(flowDataJson["elements"][index]));
      const data = elementJson["data"];

      // every element must have a label
      if (!data.hasOwnProperty("label") || data["label"] === '') {
        error_message += "Every element must have a label."
        return error_message;
      }

      if (elementJson.hasOwnProperty("source") === false) {
        if (!nodeIdVsConditions.has(elementJson["id"])) {
          nodeIdVsConditions.set(elementJson["id"], new Set());
        }
      }
      if (elementJson["type"] === "special" && elementJson.hasOwnProperty("source") === false) {
        if (nodeIdAsSource.has(elementJson["id"]) === false)
          nodeIdAsSource.set(elementJson["id"], false);
        if (nodeIdAsTarget.has(elementJson["id"]) === false)
          nodeIdAsTarget.set(elementJson["id"], false);
      }

      // number of start and end node should be one each
      if (elementJson["type"] === "input") {
        if (input_node_count === 1) {
          error_message += "There can only be one start node."
          return error_message;
        }
        input_node_count = 1;
      }

      if (elementJson["type"] === "output") {
        if (output_node_count === 1) {
          error_message += "There can only be one end node."
          return error_message;
        }
        output_node_count = 1;
      }

      if (elementJson.hasOwnProperty("source")) {
        if (elementJson["source"] === '1') {
          if (start_output === true) {
            error_message += "Start node can only have one outgoing edge."
            return error_message;
          }
          start_output = true;
        }
        else if (elementJson["source"] !== elementJson["target"]) {
          nodeIdAsSource.set(elementJson["source"], true);
        }                                                     // regular node add as source
        if (elementJson["target"] === '2')
          end_input = true;
        else if (elementJson["source"] !== elementJson["target"])
          nodeIdAsTarget.set(elementJson["target"], true);    // regular node add as target

        if (!data.hasOwnProperty("default"))                  // equivalent to default = false
          data["default"] = false;

        // all edges must have a condition(except default edge)  
        if (data["default"] === false && (data.hasOwnProperty("condition") === false || data["condition"] === "")) {
          error_message += "Every edge must have a condition(except default edge)."
          return error_message;
        }

        if (data["default"]) {
          // every node can have only one default edge
          if (defaultNodeActive.has(elementJson["source"])) {
            error_message += "Every node can have only one default edge."
            return error_message;
          }
          else {
            defaultNodeActive.add(elementJson["source"]);
          }
        }
        else if (data.hasOwnProperty("condition")) {
          if (nodeIdVsConditions.get(elementJson["source"]).has(data["condition"])) {
            // every edge connected to a node must have a unique condition
            error_message += "Every edge connected to a node must have a unique condition."
            return error_message;
          }
          else {
            nodeIdVsConditions.get(elementJson["source"]).add(data["condition"]);
          }
        }

      }

    }

    // start node has an output connector and end node has an input connector
    if (start_output === false) {
      error_message += "Start node must have an output connector."
      return error_message;
    }
    if (end_input === false) {
      error_message += "End node must have an input connector."
      return error_message;
    }
    // all regular nodes should have output as well as input connectors
    for (let entry of Array.from(nodeIdAsSource.entries())) {
      let value = entry[1];
      if (value === false) {
        error_message += "All nodes(except end) must have output connector attached to a different node."
        return error_message;
      }
    }
    for (let entry of Array.from(nodeIdAsTarget.entries())) {
      let value = entry[1];
      if (value === false) {
        error_message += "All nodes(except start) must have input connector attached to a different node."
        return error_message;
      }
    }
    return "valid";
  }

  const [reactFlowInstance, setReactFlowInstance] = useState<OnLoadParams>();
  const [elements, setElements] = useState<Elements>(initialElements);
  class Pixel {
    public x: string;
    public y: string;
    constructor(x: string, y: string) {
      this.x = x;
      this.y = y;
    }
  }
  const [devflow, setDevflow] = useState<Pixel[]>([]);
  const [publishedFSM, setPublishedFSM] = useState<Pixel[]>([]);
  const [currentState, setCurrentState] = useState<Pixel[]>([]);
  const handleChangeChk = () => {
    setChkBox(!chkbox)
  }


  const [userFlowState, setUserFlowState] = useState([[]]);
  const [triggerUrl, setTriggerUrl] = React.useState('');
  const [flowStatus, setFlowStatus] = React.useState('');
  const [flowVersion, setFlowVersion] = React.useState('');

  useEffect(() => {
    getData();
    async function getData() {
      let response;
      let data;
      try {
        response = await axios.get("flows/" + props.match.params.flow_id + "/");
        data = JSON.parse((await response).data.fsm)
        if (data) {
          setElements(data.elements || []);

        }
      } catch (error: any) {
        console.log(error.message);
      }


      let workspace;
      let workspace_id: any;
      try {
        workspace = await axios.get("flows/" + props.match.params.flow_id + "/fetch_workspace_id/")
        workspace_id = (await workspace).data
      } catch (error: any) {
        console.log(error.message)
      }
      let devflow_response;
      let devflow_data;
      try {
        devflow_response = await axios.get("workspaces/" + workspace_id + "/devflows/")
        devflow_data = (await devflow_response).data
        if (devflow.length == 0) {
          for (var value of devflow_data) {
            var p = { x: value.fields.name, y: value.fields.url }
            devflow.push(p)
          }
        }
      } catch (error: any) {
        console.log(error.message)
      }

      for (let element of elements) {
        const elementJson = JSON.parse(JSON.stringify(element));
        if (!element.hasOwnProperty('source')) {
          var p1 = { x: element.data.label, y: element.id }
          currentState.push(p1)
        }
      }

      let fsm_response;
      let fsm_data: any;
      try {
        fsm_response = await axios.get("workspaces/" + workspace_id + "/fetch_published_flows/")
        fsm_data = (await fsm_response).data
        if (publishedFSM.length == 0) {
          for (var value of fsm_data) {
            if (value.id != props.match.params.flow_id) {
              var p = { x: value.name, y: value.url }
              publishedFSM.push(p)
            }
          }
        }
      } catch (error: any) {
        console.log(error.message)
      }

      axios.get(
        "flows/" + props.match.params.flow_id + "/status/"
      ).then(response => {
        setTriggerUrl(response.data.trigger_url ? response.data.trigger_url : '');
        if (response.data.trigger_url === undefined || response.data.trigger_url === '')
          setIsTriggerDisabled(true);
        else
          setIsTriggerDisabled(false);

        setFlowStatus(response.data.is_active ? "Active" : "Inactive");
        setFlowVersion(response.data.version ? ("v" + response.data.version) : '');
      }).catch(error => {
        setAlertStatus(AlertTitleEnum.Error, "Something went wrong!", AlertSeverityEnum.Error, true);
      })
    }
  }, []);

  useEffect(() => { }, userFlowState);

  const onConnect = (params: Connection | Edge) => {
    let edgeType = 'smoothstep';
    for (let element of elements) {
      const elementJson = JSON.parse(JSON.stringify(element));
      if (element.hasOwnProperty('source') && elementJson['source'] === params['source'] && elementJson['target'] === params['target']) {
        return;
      }
    }
    if (params['source'] === params['target']) {
      setElements((els) => addEdge({ ...params, arrowHeadType: ArrowHeadType.Arrow, label: "Edge", animated: true, labelBgBorderRadius: 20, data: { label: "Edge", condition: "", default: false, conditional: "disabled" }, type: 'transition', style: { strokeWidth: 2.5, stroke: "#747474" } }, els));
      setFlowIsDirty(true);
      return;
    }
    setElements((els) => addEdge({ ...params, arrowHeadType: ArrowHeadType.Arrow, label: "Edge", animated: true, labelBgBorderRadius: 20, data: { label: "Edge", condition: "", default: false, conditional: "disabled" }, type: edgeType, style: { strokeWidth: 2.5, stroke: "#747474" } }, els));
    setFlowIsDirty(true);
  }

  const onElementsRemove = (elementsToRemove: Elements) => {
    let flag = true
    elementsToRemove.map((el) => {
      if (isNode(el)) {
        if (el.type === 'input' || el.type === 'output') {
          flag = false
        }
      }
    })
    if (flag) {
      setElements((els) => removeElements(elementsToRemove, els))
      setFlowIsDirty(true);
    }
  };
  const onLoad = (_reactFlowInstance: OnLoadParams) => {
    setReactFlowInstance(_reactFlowInstance);
    _reactFlowInstance.fitView({ padding: 1 });
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNodeFormData({ ...nodeFormData, [e.target.name]: e.target.value })
    setUrl(nodeFormData["url"])
  }
  const handleChangeUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value)
  }
  const handleEdgeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEdgeFormData({ ...edgeFormData, [e.target.name]: e.target.value })

  }
  const handleEdgeType = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEdgeFormData({ ...edgeFormData, "type": e.target.value })
  }
  const handleUrlChange = (event: SelectChangeEvent) => {
    setUrl(event.target.value);
    var urlString = event.target.value;
    setFlowPageUrl(urlString);
  };
  const handleNameChange = (event: SelectChangeEvent) => {
    setName(event.target.value);
    // var urlString = event.target.value;
    // setFlowPageUrl(urlString);
  };
  function generateStateflowUrl(urlString: any) {
    urlString = urlString.substring(urlString.indexOf("flows/") + 6);
    urlString = urlString.replace('/trigger/', '');
    const finalUrl = flowUrl + urlString;
    return finalUrl
  }
  function setFlowPageUrl(urlString: any) {
    if (urlString === undefined || urlString === '')
      return;

    if (urlString.includes("dfih")) {
      urlString = urlString.substring(urlString.indexOf("dfih-") + 5);
      urlString = urlString.substring(0, urlString.indexOf('/'));
      const finalUrl = devflowFlowsUrl + urlString;
      setDevflowPageUrl(finalUrl);
    }
    else if (urlString.includes("trigger")) {
      urlString = urlString.substring(urlString.indexOf("flows/") + 6);
      urlString = urlString.replace('/trigger/', '');
      const finalUrl = flowUrl + urlString;
      setWorkflowPageUrl(finalUrl);
    }
  }


  const onSave = () => {
    setElements((els) => els.map((el) => {
      if (isEdge(el) && el.id === edgeFormData.id && toEdge) {
        el.label = edgeFormData.label;
        el.type = edgeFormData.type;
        if (chkbox) {
          el.data = {
            ...el.data,
            condition: edgeFormData.condition,
            default: true,
            label: edgeFormData.label,
            conditional: conditionalEnabled,
            MAX_EDGE_TRANSITION: edgeFormData.MAX_EDGE_TRANSITION,
            conditionalState: conditionalState
          }
        }
        else {
          el.data = {
            ...el.data,
            condition: edgeFormData.condition,
            default: false,
            label: edgeFormData.label,
            conditional: conditionalEnabled,
            MAX_EDGE_TRANSITION: edgeFormData.MAX_EDGE_TRANSITION,
            conditionalState: conditionalState
          }
        }
      }
      else if (isNode(el) && el.id === nodeFormData.id && toNodeDisplay) {
        el.data = {
          ...el.data,
          label: nodeFormData.label,
          retry: nodeFormData.retry,
          timeout: nodeFormData.timeout,
          url: url,
          description: nodeFormData.description,
          parallel: stateflow,
          taskType: taskType,
          timeoutState: timeoutState,
          timeoutEnabled: timeoutEnabled
        };
      }
      return el;
    }))
    setFlowIsDirty(true);
    onGoBack();
  }
  const onGoBack = () => {
    setToNodeDisplay(false);
    SetToDisplay(true);
    SetToEdgeDisplay(false);
    setChkBox(false);
    setDevflowsFormActive(false);
    setActionName('');
    setActionUrl('');
    setClickedElement(undefined);
    setTaskType('');
  }

  const [flowIsDirty, setFlowIsDirty] = useState(false);

  const onDrop = (event: DragEvent) => {
    event.preventDefault();

    if (reactFlowInstance) {
      const type = event.dataTransfer.getData('application/reactflow');
      const position = reactFlowInstance.project({ x: event.clientX, y: event.clientY - 40 });
      let label1 = ""
      if (type === "output") {
        label1 = "End"
      }
      else if (type === "input") {
        label1 = "Start"
      }
      else {
        label1 = "State " + sid
        sid++
      }
      const newNode: Node = {
        id: getId(),
        type: 'special',
        position,
        data: { label: label1, parallel: [], timeoutEnabled: "disabled" },
        sourcePosition: Position.Right,
        targetPosition: Position.Left
      };
      setElements((es) => es.concat(newNode));
      setFlowIsDirty(true);
    }
  };

  enum AlertSeverityEnum {
    Success = "success",
    Warning = "warning",
    Info = "info",
    Error = "error",
  }
  enum AlertTitleEnum {
    Success = "Success!",
    Warning = "Warning!",
    Info = "Info!",
    Error = "Error!",
  }

  const [alertIsActive, setAlertIsActive] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertTitle, setAlertTitle] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<any>('');

  const setAlertStatus = (title: string, content: string, severity: string, is_active: boolean) => {
    setAlertTitle(title);
    setAlertContent(content);
    setAlertSeverity(severity);
    setAlertIsActive(is_active);
  }

  const publishFlow = () => {
    const flowData = reactFlowInstance?.toObject();
    const validityStatus = checkFlowValidity(flowData)
    if (validityStatus !== "valid") {
      alert(validityStatus);
      return;
    }
    axios.post(
      "flows/" + props.match.params.flow_id + "/publish/",
      {
        "fsm": JSON.stringify(flowData)
      }
    ).then(response => {
      const alertContent = "Published successfully!";
      setAlertStatus(AlertTitleEnum.Success, alertContent, AlertSeverityEnum.Success, true);
      setFlowIsDirty(false);
      setIsTriggerDisabled(false);
    }).catch(error => {
      setAlertStatus(AlertTitleEnum.Error, "Something went wrong!", AlertSeverityEnum.Error, true);
    })
  }
  const openTrigger = () => {
    setTrigger(true);
  }
  function IsJsonString(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  const triggerFlow = () => {
    if (IsJsonString(value)) {
      axios.post(
        "flows/" + props.match.params.flow_id + "/trigger/",
        JSON.parse(value)
      ).then(data => {
        setTrigger(false)
        const alertContent = "Triggered successfully!";
        setAlertStatus(AlertTitleEnum.Success, alertContent, AlertSeverityEnum.Success, true);
        window.location.assign("/user/flows/" + props.match.params.flow_id + "/execution/");
      }
      ).catch(error => {
        console.log(error.response.data.error);
        setAlertStatus(AlertTitleEnum.Error, "Something went wrong!", AlertSeverityEnum.Error, true);
      })
    }
    else {
      alert("Enter Valid JSON")
    }
  }
  const executionHistory = () => {
    window.location.assign("/user/flows/" + props.match.params.flow_id + "/execution/");
  }

  const onSaveFlow = () => {
    const flowData = reactFlowInstance?.toObject();
    console.log(flowData);
    axios.put(
      'flows/' + props.match.params.flow_id + "/update_flow/",
      {
        "fsm": JSON.stringify(flowData)
      }
    ).then(data => {
      console.log(data);
      const alertContent = "Saved successfully!";
      setAlertStatus(AlertTitleEnum.Success, alertContent, AlertSeverityEnum.Success, true);
      setFlowIsDirty(false);
    }).catch(error => {
      const alertContent = error.response.data.error;       // this will be updated
      setAlertStatus(AlertTitleEnum.Error, alertContent, AlertSeverityEnum.Error, true);
    })
  }


  const logToObject = () => {
    const flowData = reactFlowInstance?.toObject();
    console.log(flowData);
  }
  const [url, setUrl] = React.useState('');
  const [name, setName] = React.useState('');
  const [taskType, setTaskType] = React.useState('');

  const handleTaskType = (event: SelectChangeEvent) => {
    setTaskType(event.target.value);
  };
  const [timeoutEnabled, setTimeoutEnabled] = React.useState('');
  const [timeoutState, setTimeoutState] = useState('');
  const handleTimeoutEnable = (event: SelectChangeEvent) => {
    setTimeoutEnabled(event.target.value);
  };
  const handleTimeoutState = (event: SelectChangeEvent) => {
    setTimeoutState(event.target.value);
  };
  const [conditionalEnabled, setConditionalEnabled] = React.useState('');
  const [conditionalState, setConditionalState] = useState('');
  const handleConditionalEnable = (event: SelectChangeEvent) => {
    setConditionalEnabled(event.target.value);
  };
  const handleConditionalState = (event: SelectChangeEvent) => {
    setConditionalState(event.target.value);
  };
  const cleanAlertState = () => {
    setAlertIsActive(false);
    setAlertTitle('');
    setAlertContent('');
    setAlertSeverity('');
  }

  window.onbeforeunload = function (event) {
    if (!flowIsDirty) {
      return undefined;
    }
    event.preventDefault();
    event.returnValue = '';
  };

  function refreshState() {
    getData();
    async function getData() {
      const state_array = []
      for (let element of elements) {
        const elementJson = JSON.parse(JSON.stringify(element));
        if (!element.hasOwnProperty('source')) {
          if (element.id !== '1') {
            var p1 = { x: element.data.label, y: element.id }
            state_array.push(p1)
          }
        }
      }
      setCurrentState(state_array)
    }
  }
  function refreshDevflowsDropdown() {
    getData();
    let workspace;
    let workspace_id: any;
    async function getData() {
      try {
        workspace = await axios.get("flows/" + props.match.params.flow_id + "/fetch_workspace_id/")
        workspace_id = (await workspace).data
      } catch (error: any) {
        console.log(error.message)
      }
      let devflow_response;
      let devflow_data: any;
      try {
        devflow_response = await axios.get("workspaces/" + workspace_id + "/devflows/")
        devflow_data = (await devflow_response).data
      } catch (error: any) {
        console.log(error.message)
      }
      const devflow_array = []
      for (var value of devflow_data) {
        var p = { x: value.fields.name, y: value.fields.url }
        devflow_array.push(p)
      }
      setDevflow(devflow_array);
    }
  }
  const useStyles = makeStyles((theme) => ({
    heading: {
      textAlign: "center",
      margin: theme.spacing(1, 0, 4),
    },
    submitButton: {
      marginTop: theme.spacing(4),
    },
  }));

  const { heading, submitButton } = useStyles();
  interface IFormInput {
    [key: string]: string;
    flowName: string;
    userName: string;
  }

  const addAction = async (data: IFormInput) => {
    let workspace;
    let workspace_id
    try {
      workspace = await axios.get("flows/" + props.match.params.flow_id + "/fetch_workspace_id/")
      workspace_id = (await workspace).data
    } catch (error: any) {
      console.log(error.message)
    }
    const actionUrl = data["actionURL"];
    const body = {
      name: data["actionName"],
      url: data["actionURL"],
      workspace_id: workspace_id
    };

    axios.post(
      "workspaces/"+ workspace_id + "/add_devflow/",
      body
    ).then(data => {
      const alertContent = "Action added successfully!";
      setAlertStatus(AlertTitleEnum.Success, alertContent, AlertSeverityEnum.Success, true);
      refreshDevflowsDropdown();
      setUrl(actionUrl);
      setActionName('');
      setActionUrl('');
    }).catch(err => {
      console.log(err);
      setAlertStatus(AlertTitleEnum.Error, "Something went wrong!", AlertSeverityEnum.Error, true);
    })
  };

  const {
    register,
    handleSubmit,
  } = useForm<IFormInput>();

  const [json, setJson] = useState<string>();

  const onSubmitActions = (data: IFormInput) => {
    setJson(JSON.stringify(data));
    console.log(JSON.stringify(data));
    addAction(data);
  };

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  }));
  const drawerWidth = 340;
  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };
  const handleOpenClick = (url: string) => {
    window.open(url);
  }
  const [devflowsFormActive, setDevflowsFormActive] = React.useState(false);

  const setDevflowsFormStatus = () => {
    setDevflowsFormActive(!devflowsFormActive);
  }

  const [workflowPageUrl, setWorkflowPageUrl] = React.useState('');
  const [devflowPageUrl, setDevflowPageUrl] = React.useState('');
  const [actionName, setActionName] = React.useState('');
  const [actionUrl, setActionUrl] = React.useState('');
  const [isTriggerDisabled, setIsTriggerDisabled] = useState(false);
  const onDeleteElement = () => {
    if (clickedElement === undefined) {
      onGoBack();
      return;
    }
    if (isEdge(clickedElement)) {
      onElementsRemove([clickedElement]);
    }
    else {
      const edges: any = elements.filter((element: Node | Edge) => isEdge(element));
      const edgesToRemove = getConnectedEdges([clickedElement], edges);
      onElementsRemove([...[clickedElement], ...edgesToRemove]);
    }
    onGoBack();
  }
  return (
    <div className="flow">
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        anchor="right"
        open={openDrawer}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <TextField
          variant="outlined"
          id="outlined-read-only-input"
          label="Trigger URL"
          defaultValue={triggerUrl}
          InputProps={{
            readOnly: true,
          }}
          size="small"
          style={{ width: 300, margin: 20 }}
          multiline
        />
        <TextField
          variant="outlined"
          id="outlined-read-only-input"
          label="Status"
          defaultValue={flowStatus}
          InputProps={{
            readOnly: true,
          }}
          size="small"
          style={{ width: 300, margin: 20 }}
        />
        <TextField
          variant="outlined"
          id="outlined-read-only-input"
          label="Version"
          defaultValue={flowVersion}
          InputProps={{
            readOnly: true,
          }}
          size="small"
          style={{ width: 300, margin: 20 }}
        />
      </Drawer>
      <Sidebar />
      {alertIsActive ?
        <Snackbar open={alertIsActive} autoHideDuration={6000} onClose={cleanAlertState} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
          <Alert variant="filled" severity={alertSeverity} onClose={cleanAlertState}>
            <AlertTitle>{alertTitle}</AlertTitle>
            <strong>{alertContent}</strong>
          </Alert>
        </Snackbar> : <></>
      }
      <ReactFlowProvider>
        <div className="reactflow-wrapper">
          <ReactFlow
            elements={elements}
            onConnect={onConnect}
            onElementsRemove={onElementsRemove}
            onLoad={onLoad}
            onDrop={onDrop}
            onDragOver={onDragOver}
            onElementClick={onElementClick}
            snapToGrid={true}
            nodeTypes={nodeTypes}
            edgeTypes={{ transition: TransitionEdge }}
          >

            <Background variant={BackgroundVariant.Lines} />
            <Background variant={BackgroundVariant.Lines} />
            <div style={{ position: 'absolute', left: 10, top: 10, zIndex: 4 }}>
            </div>
            <div style={{ position: 'absolute', right: 10, top: 10, zIndex: 4 }}>
              {/* <Button onClick={logToObject}>toObject</Button> */}
              <Button variant="contained" onClick={onSaveFlow}>Save</Button>
              <Button variant="contained" onClick={publishFlow}>Publish</Button>
              <Button variant="contained" onClick={openTrigger} disabled={isTriggerDisabled}>Trigger</Button>
              <Button variant="contained" onClick={executionHistory}>Execution History</Button>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerOpen}
                sx={{ ...(open && { display: 'none' }) }}
              >
                <MenuIcon />
              </IconButton>
            </div>
            <Controls />
          </ReactFlow>
        </div>
      </ReactFlowProvider>
      <Dialog open={toEdge} onClose={handleCloseEdge}>
        <DialogTitle> Edge Input</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Define your Edge
          </DialogContentText>
          <br></br>
          <TextField
            id="outlined-multiline-flexible"
            label="Label"
            name='label'
            value={edgeFormData["label"]}
            style={{ width: 500 }}
            onChange={handleEdgeChange}
          />
        </DialogContent>

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox checked={chkbox} disabled={!checkboxIsActive} onChange={handleChangeChk} value={chkbox} name="default" style={{ marginLeft: "5%" }} />
            }
            label="Default"
          />
        </FormGroup>
        {!chkbox ? <>
          <DialogContent>
            <TextField
              id="outlined-multiline-flexible"
              label="Transition Parameter"
              name='condition'
              value={edgeFormData["condition"]}
              style={{ width: 500 }}
              onChange={handleEdgeChange}
            />
          </DialogContent>
          <div style={{marginLeft:"20px"}}>
          <FormControl component="fieldset" style={{ width: 450 }}>
                <FormLabel component="legend">Conditional Edge</FormLabel>
                <RadioGroup
                  row aria-label="gender"
                  name="row-radio-buttons-group"
                  value={conditionalEnabled}
                  onChange={handleConditionalEnable}
                >
                  <FormControlLabel value="enabled" control={<Radio />} label="Enabled" />
                  <FormControlLabel value="disabled" control={<Radio />} label="Disabled" />
                </RadioGroup>
              </FormControl>
              {conditionalEnabled === "enabled" ? <>
                <DialogContent>
                  <TextField
                    variant="outlined"
                    id="outlined-multiline-flexible"
                    label="Max Edge Transition Limit (>0)"
                    name='MAX_EDGE_TRANSITION'
                    value={edgeFormData["MAX_EDGE_TRANSITION"]}
                    style={{ width: 450 }}
                    onChange={handleEdgeChange}
                  />
                  <br>
                  </br>


                </DialogContent>
                <FormControl required sx={{ marginLeft: "23px", minWidth: 450 }}>
                  <InputLabel id="demo-simple-select-required-label">Go To State on Maximum Limit</InputLabel>
                  <Select
                    labelId="demo-simple-select-required-label"
                    id="demo-simple-select-required"
                    name="url"
                    value={conditionalState}
                    label="Go To State on Maximum Limit *"
                    onChange={handleConditionalState}
                  >
                    {currentState.map(x => (
                      <MenuItem value={x.y}>
                        {x.x}
                      </MenuItem>
                    ))}

                  </Select>
                
                </FormControl>
              </>
                : null}
            </div>
          </>
          : null}
          
        <FormControl component="fieldset" style={{ width: 500, padding: 20, marginTop:"40px" }}>
          <FormLabel component="legend">Edge Style</FormLabel>
          <RadioGroup
            row aria-label="gender"
            name="row-radio-buttons-group"
            value={edgeFormData["type"]}
            onChange={handleEdgeType}
          >
            <FormControlLabel value="smoothstep" control={<Radio />} label="SmoothStep" />
            <FormControlLabel value="straight" control={<Radio />} label="Straight" />
            <FormControlLabel value="default" control={<Radio />} label="Curved" />
          </RadioGroup>
        </FormControl>
        <DialogActions>
          <Button onClick={onDeleteElement} style={{ backgroundColor: '#ff9999', marginLeft: '0px' }}>Delete</Button>
          <Button onClick={onGoBack}>Cancel</Button>
          <Button onClick={onSave}>Save</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={toNodeDisplay} onClose={handleCloseState} >
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={toValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange1} aria-label="lab API tabs example">
                <Tab label="State Info" value="1" />
                <Tab label="Task Type" value="3" />
                <Tab label="Timeout" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <DialogTitle> Node Input</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Define your state
                </DialogContentText>
                <br></br>
                <TextField
                  variant="outlined"
                  id="outlined-multiline-flexible"
                  label="Label"
                  name='label'
                  value={nodeFormData["label"]}
                  style={{ width: 500 }}
                  onChange={handleChange}
                  required
                />
                <br></br>
                <br></br>
                <TextField
                  variant="outlined"
                  id="outlined-multiline-flexible"
                  label="Task Description"
                  name='description'
                  value={nodeFormData["description"]}
                  style={{ width: 500 }}
                  onChange={handleChange}
                />
                <br></br>
                <br></br>
                {/* <TextField
            variant="outlined"
            id="outlined-multiline-flexible"
            label="Timeout (In seconds)"
            name='timeout'
            value={nodeFormData["timeout"]}
            style={{ width: 500 }}
            onChange={handleChange}
          />
          <br>
          </br> <br></br> */}


              </DialogContent>




            </TabPanel>
            <TabPanel value="2">

              <FormControl component="fieldset" style={{ width: 500 }}>
                <FormLabel component="legend">Timeout Configuration</FormLabel>
                <RadioGroup
                  row aria-label="gender"
                  name="row-radio-buttons-group"
                  value={timeoutEnabled}
                  onChange={handleTimeoutEnable}
                >
                  <FormControlLabel value="enabled" control={<Radio />} label="Enabled" />
                  <FormControlLabel value="disabled" control={<Radio />} label="Disabled" />
                </RadioGroup>
              </FormControl>
              {timeoutEnabled === "enabled" ? <>
                <DialogContent>
                  {/* <DialogContentText>
            Timeout Configuration
          </DialogContentText> */}
                  {/* <br></br> */}
                  <TextField
                    variant="outlined"
                    id="outlined-multiline-flexible"
                    label="Timeout (In seconds)"
                    name='timeout'
                    value={nodeFormData["timeout"]}
                    style={{ width: 500 }}
                    onChange={handleChange}
                  />
                  <br>
                  </br>


                </DialogContent>
                <FormControl required sx={{ marginLeft: "23px", minWidth: 500 }}>
                  <InputLabel id="demo-simple-select-required-label">Go to State on Timeout</InputLabel>
                  <Select
                    labelId="demo-simple-select-required-label"
                    id="demo-simple-select-required"
                    name="url"
                    value={timeoutState}
                    label="Go To State on Timeout *"
                    onChange={handleTimeoutState}
                  >
                    {currentState.map(x => (
                      <MenuItem value={x.y}>
                        {x.x}
                      </MenuItem>
                    ))}

                  </Select>
                  {/* <Button target="_blank" href={workflowPageUrl} endIcon={<OpenInNew />} style={{ marginLeft: 'auto', width: "200px" }}>Open workflow</Button> */}
                  {/* <FormHelperText>Required</FormHelperText> */}
                </FormControl>
              </>
                : null}




            </TabPanel>
            <TabPanel value="3">


              <FormControl component="fieldset" style={{ width: 500 }}>
                <FormLabel component="legend">Task Type</FormLabel>
                <RadioGroup
                  row aria-label="gender"
                  name="row-radio-buttons-group"
                  value={taskType}
                  onChange={handleTaskType}
                >
                  <FormControlLabel value="devflow-task" control={<Radio />} label="Devflow Task" />
                  <FormControlLabel value="external-url" control={<Radio />} label="External URL" />
                  <FormControlLabel value="published-fsm" control={<Radio />} label="Published Stateflow" />
                  <FormControlLabel value="parallel-state" control={<Radio />} label="Parallel States" />
                  <FormControlLabel value="no-op-task" control={<Radio />} label="No Operation Task" />
                  <FormControlLabel value="dynamic-task" control={<Radio />} label="Dynamic Task" />
                </RadioGroup>
              </FormControl>
              {taskType === "external-url" ?

                <TextField
                  id="outlined-multiline-flexible"
                  label="External Task Url"
                  name='url'
                  value={url}
                  style={{ width: 500, marginTop: 20 }}
                  onChange={handleChangeUrl}
                /> : null}
              <br></br> <br></br>
              {taskType === "devflow-task" ?
                <FormControl required sx={{ m: 0, minWidth: 500 }}>
                  <InputLabel id="demo-simple-select-required-label">Devflow Task</InputLabel>
                  <Select
                    labelId="demo-simple-select-required-label"
                    id="select-devflow"
                    value={url}
                    label="Devflow Task *"
                    onChange={handleUrlChange}
                  >
                    {devflow.map(x => (
                      <MenuItem key={x.y} value={x.y}>
                        {x.x}
                      </MenuItem>
                    ))}

                  </Select>
                  <Button target="_blank" href={devflowPageUrl} endIcon={<OpenInNew />} style={{ marginLeft: 'auto', width: "250px" }}>Open flow in devflows</Button>
                  <br></br>
                  <form onSubmit={handleSubmit(onSubmitActions)} noValidate>
                    <Button variant="outlined" startIcon={<AddIcon />} onClick={setDevflowsFormStatus}>
                      Add new devflows task
                    </Button>
                    {devflowsFormActive ?
                      <div>
                        <TextField
                          {...register("actionName")}
                          variant="outlined"
                          margin="normal"
                          label="Flow Name"
                          fullWidth
                          required
                          size="small"
                          value={actionName}
                          onChange={(e) => setActionName(e.target.value)}
                        />
                        <TextField
                          {...register("actionURL")}
                          variant="outlined"
                          margin="normal"
                          label="Flow URL"
                          type="url"
                          fullWidth
                          required
                          size="small"
                          value={actionUrl}
                          onChange={(e) => setActionUrl(e.target.value)}
                        />
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          className={submitButton}
                        >
                          Add
                        </Button>
                      </div>
                      : null}
                  </form>
                </FormControl> : null}
              {taskType === "published-fsm" ?
                <FormControl required sx={{ m: 0, minWidth: 500 }}>
                  <InputLabel id="demo-simple-select-required-label">Published Stateflow</InputLabel>
                  <Select
                    labelId="demo-simple-select-required-label"
                    id="demo-simple-select-required"
                    value={url}
                    label="Published Stateflow *"
                    onChange={handleUrlChange}
                  >
                    {publishedFSM.map(x => (
                      <MenuItem value={x.y}>
                        {x.x}
                      </MenuItem>
                    ))}

                  </Select>
                  <Button target="_blank" href={workflowPageUrl} endIcon={<OpenInNew />} style={{ marginLeft: 'auto', width: "200px" }}>Open workflow</Button>
                  <FormHelperText>Required</FormHelperText>

                </FormControl> : null}
              {taskType === "parallel-state" ?
                <div>
                  {/* <TextField
              id="outlined-multiline-flexible"
              label="State Name"
              name='name'
              value={name}
              style={{ width: 500, marginTop: 20 }}
              onChange={(e) => setName(e.target.value)}
          />
          <br></br> <br></br> */}
                  {
                    stateflow.map((x, index) => {
                      return (
                        <div className="box">
                          {/* <input
                    name="name"
                    placeholder="Name"
                    value={x}
                    onChange={e => handleInputChange(e, index)}
                  /> */}


                          <FormControl required sx={{ m: 0, minWidth: 160 }}>
                            <TextField
                              id="outlined-basic"
                              label="State Name"
                              name='name'
                              value={x.name}
                              style={{ width: 220, marginRight: 5, height: 85 }}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </FormControl>
                          {/* <br></br> <br></br> */}
                          <FormControl required sx={{ m: 0, minWidth: 200 }}>
                            <InputLabel id="demo-simple-select-required-label">Published Stateflow</InputLabel>
                            <Select
                              labelId="demo-simple-select-required-label"
                              id="demo-simple-select-required"
                              name="url"
                              value={x.url}
                              label="Published Stateflow *"
                              onChange={(e) => handleInputChange(e, index)}
                            >
                              {publishedFSM.map(x => (
                                <MenuItem value={x.y}>
                                  {x.x}
                                </MenuItem>
                              ))}

                            </Select>
                            {/* <Button target="_blank" href={workflowPageUrl} endIcon={<OpenInNew />} style={{ marginLeft: 'auto', width: "200px" }}>Open workflow</Button> */}
                            {/* <FormHelperText>Required</FormHelperText> */}
                          </FormControl>

                          {/* {stateflow.length !== 0 && <Button onClick={() => handleRemoveClick(index)} style={{backgroundColor: '#ff9999', marginLeft: '0px'}}>Remove</Button>} */}
                          {/* <Button  target="_blank" href={generateStateflowUrl(x.url)} endIcon={<OpenInNew />} style={{ marginBottom: "45px" }}></Button> */}
                          <OpenInNew onClick={() => handleOpenClick(generateStateflowUrl(x.url))} style={{ marginLeft: '0px', fontSize: '45px' }}></OpenInNew>
                          {stateflow.length !== 0 && <DeleteIcon onClick={() => handleRemoveClick(index)} style={{ marginLeft: '0px', fontSize: '55px' }}>Remove</DeleteIcon>}
                          {stateflow.length - 1 === index && <AddCircleIcon onClick={() => handleAddClick()} style={{ marginLeft: '1px', fontSize: '55px' }}>Add New State</AddCircleIcon>}
                          {/* <Button  target="_blank" href={generateStateflowUrl(x.url)} endIcon={<OpenInNew />} style={{ marginLeft: '230px', width: "200px", marginBottom: "10px" }}>Open Stateflow</Button> */}
                          {/* {stateflow.length - 1 === index && <Button onClick={() => handleAddClick()} style={{backgroundColor: '#ff9999', marginLeft: '5px'}}>Add New State</Button>} */}

                        </div>

                      )
                    }
                    )}
                  {stateflow.length === 0 && <AddCircleIcon onClick={() => handleAddClick()} style={{ marginLeft: '0px', fontSize: '55px' }}>Add New State</AddCircleIcon>}
                  <br></br>
                </div> : null}




            </TabPanel>
          </TabContext>
        </Box>

        <DialogActions>
          <Button onClick={onDeleteElement} style={{ backgroundColor: '#ff9999', marginLeft: '0px' }}>Delete</Button>
          <Button onClick={onGoBack}>Cancel</Button>
          <Button onClick={onSave}>Save</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={trigger} onClose={handleCloseTrigger}>
        <DialogTitle>Trigger</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter Json as a input
          </DialogContentText>
          <br></br>
          <TextField
            id="outlined-multiline-flexible"
            label="JSON"
            multiline
            maxRows={16}
            minRows={8}
            value={value}
            style={{ width: 500 }}
            onChange={handleChangeTrigger}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTrigger}>Cancel</Button>
          <Button onClick={triggerFlow}>Trigger</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};




export default Flow;
