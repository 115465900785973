import React from 'react';
import axios from '../../axios';

interface Props {
    classes: any;
}

type State = {
    username: string
    password: string
};

class Auth extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { username: '', password: '' };
        authFunction();
    }
    render() {
        return (<p>Loading...</p>);
    }
}

// Function to post code received from DevConnect to backend
function authFunction() {
    let code = getQueryStringValue('code');
    console.log('code: ' + code);
    let data = {
        'code': code
    };
    // djangoAPI is basically axios
    // oidc/ is path to my backend view
    axios.post('oidc/', data)
        .then(response => {
            console.log(response);
            var auth_token = response.data.token;
            console.log(auth_token);

            localStorage.setItem("token", auth_token);
            // this.props.history.push('/dashboard');
            window.location.href = "user/dashboard";
        })
        .catch(e => {
            console.log(e);
            console.log('Invalid details provided.');
        })
}

// Get query parameters
function getQueryStringValue(key: string) {
    console.log("winsearch: " + window.location.search);
    var params: any = window.location.href.split('#');
    console.log(params);
    params = params[1];
    return decodeURIComponent(
        params.replace(
            new RegExp(
                "^(?:.*[&\\?]" +
                encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$",
                "i"
            ), "$1"
        )
    );
}

export default Auth;