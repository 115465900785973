// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@mui/material/TablePagination';
import React from 'react';
// core components
import tableStyle from '../../assets/jss/material-dashboard-react/components/tableStyle';
import './TableButton.css';


function CustomTable({ ...props }: any) {
  const open = (id: string, redirectUrl: string) => {
    window.location.assign(redirectUrl + id);
  }
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const { classes, tableHead, tableData, tableHeaderColor, redirectUrl, showFirstIndex = false, addButton = true, addId = true, addVisualisationButton = false, visualisationPageUrl } = props;
  function AddButtonToTable(id_prop: any) {
    var id = "";
    if (addId)
      id = id_prop['prop'];
    if (addButton === true)
      return (
        <TableCell className={classes.tableCell}>
          <button className="TableButton" onClick={() => open(id, redirectUrl)}>Open</button>
        </TableCell>);
    return null;    
  }
  const openVisualisationPage = (id: string, redirectUrl: string) => {
    window.location.assign(redirectUrl + id + "/live");
  }
  function AddVisualisationButtonToTable(id_prop: any) {
    var id = "";
    if (addId)
      id = id_prop['prop'];
    if (addVisualisationButton === true)
        return (
    <TableCell className={classes.tableCell}>
      <button className="TableButton" onClick={() => openVisualisationPage(id, visualisationPageUrl)}>Open</button>
    </TableCell>);
    return null;    
  }
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + 'TableHeader']}>
            <TableRow>
              {tableHead.map((prop: any, key: any) => {
                return (
                  <TableCell style={{fontWeight: 600, color:"grey"}}
                    className={classes.tableCell + ' ' + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((prop: any, key: any) => {
            if (prop.length !== 0)
              return (
                <TableRow key={key}> 
                  {prop.map((p: any, k: any) => {
                    // skip workspaceID as we dont want to show this but is required as a prop
                    if (showFirstIndex || k !== 0)                                        
                    return (
                      <TableCell className={classes.tableCell} key={k}>
                        {p}
                      </TableCell>
                    );
                  })}
                  <AddVisualisationButtonToTable prop={prop[0]}/>
                  <AddButtonToTable prop={prop[0]}/>
                </TableRow>
              );
            else
              return <TableRow> </TableRow>
          })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={tableData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: 'gray'
};

export default withStyles(tableStyle)(CustomTable);
