import { createStyles } from '@material-ui/core';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import React, { useEffect, useState } from 'react';
import axios from '../../axios';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import CustomizedAccordions from '../../components/EventTrace/EventTrace';
import GridContainer from '../../components/Grid/GridContainer';
// core components
import GridItem from '../../components/Grid/GridItem';
import Table from '../../components/Table/Table';
import '../../css/Buttons.css';

const styles = createStyles({
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0'
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF'
    }
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: 300,
    fontFamily: '\'Roboto\', \'Helvetica\', \'Arial\', sans-serif',
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: 400,
      lineHeight: 1
    }
  }
});


function ExecutionDetails(props: any) {

  const [flowExecutionDetails, setFlowExecutionDetails] = useState([[]]);
  const [fullExecutionTrace, setFullExecutionTrace] = useState(new Array());

  useEffect(() => {
    getData();
    
    async function getData() {
      let response: any;
      try {
        response = await axios.get("executions/" + props.match.params.execution_id + "/fetch_execution_summary/");
        const jsonData = response["data"];
        const arrayData = new Array();
        const elementArray = [];
        elementArray.push(jsonData["status"]);
        elementArray.push(jsonData["input"]);
        elementArray.push(jsonData["output"]);
        arrayData.push(elementArray);
        console.log(arrayData);
        setFlowExecutionDetails(arrayData);
        fetchCompleteTrace();
      } catch (error: any) {
        console.log(error.message)
      }

    }

  }, []);

  const fetchCompleteTrace = () => {
    return getData();
    async function getData() {
      let response: any;
      try {
        response = await axios.get("executions/" + props.match.params.execution_id + "/fetch_events/");
        const jsonData = response["data"];
        var traceArray = new Array();
        traceArray.push(jsonData['start']);
        for (let i = 0; i < 10000; i++) {
          if (jsonData.hasOwnProperty(i)) {
            traceArray.push(jsonData[i]);
          }
          else break;
        }
        if (jsonData.hasOwnProperty('exit'))
          traceArray.push(jsonData['exit']);
  
        setFullExecutionTrace(traceArray);
        return jsonData;
      } catch (error: any) {
        console.log(error.message)
      }

    }
  }

  useEffect(() => { }, flowExecutionDetails);

  const { classes } = props;

  const refreshPage = () => {
    window.location.reload()
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <button className="button-62" role="button" onClick={refreshPage} style={{ float: "right" }}>Refresh</button>
            <h4 className={classes.cardTitleWhite}>Execution Details</h4>
            <p className={classes.cardCategoryWhite}>
              Details
            </p>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor=""
              tableHead={['Status', 'Input', 'Output']}
              tableData={flowExecutionDetails}
              redirectUrl={"/user/flows/" + props.match.params.flow_id + "/execution/" + props.match.params.execution_id + "/live"}  // this will be updated
              showFirstIndex={true}
              addButton={false}
              addId={false}
            />
          </CardBody>
        </Card>
      </GridItem>
      <CustomizedAccordions accordionData={fullExecutionTrace} classes={classes} />
    </GridContainer>
  );
}

export default withStyles(styles)(ExecutionDetails);
