import { createStyles, FormControl } from '@material-ui/core';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import React, { useEffect, useState } from 'react';
import axios from '../../axios';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import GridContainer from '../../components/Grid/GridContainer';
// core components
import GridItem from '../../components/Grid/GridItem';
import Table from '../../components/Table/Table';
import '../../css/Buttons.css';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import QuickFilteringGrid from '../../components/ExecutionGrid/ExecutionGrid';

const styles = createStyles({
    cardCategoryWhite: {
        '&,& a,& a:hover,& a:focus': {
            color: 'rgba(255,255,255,.62)',
            margin: '0',
            fontSize: '14px',
            marginTop: '0',
            marginBottom: '0'
        },
        '& a,& a:hover,& a:focus': {
            color: '#FFFFFF'
        }
    },
    cardTitleWhite: {
        color: '#FFFFFF',
        marginTop: '0px',
        minHeight: 'auto',
        fontWeight: 300,
        fontFamily: '\'Roboto\', \'Helvetica\', \'Arial\', sans-serif',
        marginBottom: '3px',
        textDecoration: 'none',
        '& small': {
            color: '#777',
            fontSize: '65%',
            fontWeight: 400,
            lineHeight: 1
        }
    }
});


function AnalyticsTable(props: any) {

    const [queryParameter, setQueryParameter] = React.useState("");
    const [allStates, setAllStates] = React.useState([[]]);
    const [executionState, setExecutionState] = React.useState<any[]>([]);
    useEffect(() => {
        getData();
        async function getData() {
            let response: any;
            try {
                response = await axios.get("flows/" + props.match.params.flow_id + "/fetch_states/");
                const jsonData = response.data.states;
                var arrayData = new Array();
                for (let jsonElement of jsonData) {
                    if (jsonElement !== 'start' && jsonElement !== 'end') {
                        arrayData.push(jsonElement);
                    }
                }
                setAllStates(arrayData);
            } catch (error: any) {
                console.log(error.message)
            }
        }

    }, []);

  useEffect(() => {
    getData();
    async function getData() {
      let response: any;
      try {
        response = await axios.post("flows/" + props.match.params.flow_id + "/executions_state/",
        {
            "q": queryParameter
        });
        
        const jsonData = response.data;
        var arrayData = new Array();
        for (let jsonElement of jsonData) {
          const jsonElementField = jsonElement.fields;
          let row =
          {
            id: jsonElement["pk"],
            execution_trace_id: jsonElementField["execution_trace_id"],
            start_date: jsonElementField["started_time"],
            current_state: jsonElementField["current_state"]
          }
          arrayData.push(row);
        }
        setExecutionState(arrayData);
      } catch (error: any) {
        console.log(error.message);
      }

    }

  }, [queryParameter]);


    const { classes } = props;


    const handleChange = (event: SelectChangeEvent) => {
        setQueryParameter(event.target.value);
    };

    return (
        <>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="info">

                            <div style={{ float: "right" }}>
                                <FormControl>
                                    <InputLabel style={{ "color": "white", "fontSize": "14px" }} id="demo-simple-select-required-label">Filter by state</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled"
                                        value={queryParameter}
                                        onChange={handleChange}
                                        label="Filter by state"
                                        style={{ float: 'right', width: '100px', height: '40px' }}
                                        color="info"
                                        IconComponent={ArrowDropDownCircleIcon}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {allStates.map(x => (
                                            <MenuItem value={x}>
                                                {x}
                                            </MenuItem>
                                        ))}
                                    </Select>

                                </FormControl>
                            </div>
                            <h4 className={classes.cardTitleWhite}>Executions</h4>
                            <p className={classes.cardCategoryWhite}>
                                Flow's Execution details
                            </p>

                        </CardHeader>
                        <CardBody>
                            <QuickFilteringGrid
                                tableHeaderColor=""
                                tableHead={['Execution Id', 'Execution Trace Id', 'Start Date', 'Current State', 'Visualisation', 'Execution Details']}
                                tableData={executionState}
                                showFirstIndex={true}
                                addVisualisationButton={true}
                                addButton={true}
                                redirectUrl={"/user/flows/" + props.match.params.flow_id + "/execution/"}
                                visualisationPageUrl = {"/user/flows/" + props.match.params.flow_id + "/execution/"}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </>
    );
}

export default withStyles(styles)(AnalyticsTable);
