// @material-ui/core
import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';
import dashboardStyle from '../../assets/jss/material-dashboard-react/views/dashboardStyle';
import axios from '../../axios';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import GridContainer from '../../components/Grid/GridContainer';
// core components
import GridItem from '../../components/Grid/GridItem';
import Table from '../../components/Table/Table';

interface Props {
  classes: any;
}

interface State {
  value: number,
  flows: Array<Array<string>>;
}

class Dashboard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      value: 0,
      flows: [[]]
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeIndex = this.handleChangeIndex.bind(this);
  }
  handleChange = (event: any, value: number) => {
    this.setState({ value });
  }

  handleChangeIndex = (index: number) => {
    this.setState({ value: index });
  }


  componentDidMount() {
    axios.get("flows/recent_flow/").then((response) => {
      const jsonData = response["data"];
      console.log(jsonData);
      const arrayData = [];
      for (const jsonElement of jsonData) {
        const elementArray = [];
        elementArray.push(jsonElement["pk"]);
        elementArray.push(jsonElement["fields"]["name"]);
        elementArray.push(jsonElement["fields"]["updated_at"]);
        arrayData.push(elementArray);
      }
      this.setState({ flows: arrayData });
    }).catch(err => console.log(err.message))
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="info">
                <h4 className={classes.cardTitleWhite}>Recent Flows</h4>
                <p className={classes.cardCategoryWhite}>
                  Flows you recently worked on
                </p>
              </CardHeader>
              <CardBody>
                <Table
                  tableHeaderColor=""
                  tableHead={['Flow Name', 'Last Update At', 'Click to open!']}
                  tableData={this.state.flows}
                  redirectUrl="/user/flows/"
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(dashboardStyle)(Dashboard);
