// @material-ui/icons
import Dashboard from '@material-ui/icons/Dashboard';
// core components/views for user layout
import DashboardPage from './views/Dashboard/Dashboard';
import TableList from './views/TableList/TableList';


const dashboardRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: Dashboard,
    component: DashboardPage,
    layout: '/user'
  },
  {
    path: '/workspaces',
    name: 'Workspaces',
    icon: 'content_paste',
    component: TableList,
    layout: '/user'
  }   
];

export default dashboardRoutes;
