import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, { DragEvent, useEffect, useState } from 'react';
import ReactFlow, {
  Background,
  BackgroundVariant, Controls, ElementId, Elements, isNode, OnLoadParams, Position, ReactFlowProvider, Handle,
} from 'react-flow-renderer';
import { v4 as uuid_v4 } from "uuid";
import axios from '../../axios';
import CustomizedAccordions from '../../components/EventTrace/EventTrace';
import '../../css/Styles.css';
import '../../css/Flow.css';
import Sidebar from '../../Sidebar';
import TransitionEdge from '../../helpers/TransitionEdge';


const initialElements = [{ id: '1', type: 'input', data: { label: 'Start' }, position: { x: 250, y: 250 }, sourcePosition: Position.Right, targetPosition: Position.Left },
{ id: '2', type: 'output', data: { label: 'End' }, position: { x: 750, y: 250 }, sourcePosition: Position.Right, targetPosition: Position.Left }];

const customNodeStyles = {
  background: '#ffffff',
  color: '#FFF',
  padding: 10,
  height: 17,
  width: 160,
  border: '2px solid #000000',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  verticalAlign: 'middle',
  borderRadius: 3,
};
const customNodeParentStyles = {
  background: '#ffffff',
  color: '#FFF',
  padding: 10,
  height: 'auto',
  width: 200,
  border: '2px solid #000000',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  verticalAlign: 'middle',
  borderRadius: 3,
};
const customNodeChildStyles = {
  background: '#ffffff',
  color: '#FFF',
  padding: 10,
  height: 15,
  width: 180,
  border: '2px solid #000000',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  verticalAlign: 'middle',
  borderRadius: 3,
  marginTop: "10px"
};
const CustomSpecialNodeComponent = ({ data }: { data: any }) => {
  return (
    <div style={customNodeParentStyles}>
      <Handle type="target" position={Position.Left} id="a" /*style={{ top: '30%'}}*/ style={{
        background: '#ff69b4', width: '8px',
        height: '8px', top: '30%'
      }} />
      <Handle type="target" position={Position.Top} id="b" style={{
        background: '#ff69b4', width: '8px',
        height: '8px', left: '10%'
      }} />
        <Handle type="target" position={Position.Top} id="s" style={{
        background: '#ff69b4', width: '8px',
        height: '8px', left: '50%'
      }} />
      <Handle type="target" position={Position.Top} id="z" style={{
        background: '#ff69b4', width: '8px',
        height: '8px', left: '30%'
      }} />

      <Handle
        type="source"
        position={Position.Right}
        id="c"
        style={{
          background: '#0047AB', width: '8px',
          height: '8px', top: '30%'
        }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="y"
        style={{
          background: '#0047AB', width: '8px',
          height: '8px', left: '30%'
        }}
      />
       <Handle
        type="source"
        position={Position.Bottom}
        id="q"
        style={{
          background: '#0047AB', width: '8px',
          height: '8px', left: '50%'
        }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="d"
        style={{
          background: '#0047AB', width: '8px',
          height: '8px', left: '10%'
        }}
      />
      <Handle type="source" position={Position.Left} id="e" style={{
        background: '#0047AB', width: '8px',
        height: '8px', top: '70%'
      }} />
      <Handle type="source" position={Position.Top} id="f" style={{
        background: '#0047AB', width: '8px',
        height: '8px', left: '90%'
      }} />
      <Handle type="source" position={Position.Top} id="x" style={{
        background: '#0047AB', width: '8px',
        height: '8px', left: '70%'
      }} />
      <Handle
        type="target"
        position={Position.Right}
        id="g"
        style={{
          background: '#ff69b4', width: '8px',
          height: '8px', top: '70%'
        }} />
      <Handle
        type="target"
        position={Position.Bottom}
        id="h"
        style={{
          background: '#ff69b4', width: '8px',
          height: '8px', left: '90%'
        }}
      />
      <Handle
        type="target"
        position={Position.Bottom}
        id="w"
        style={{
          background: '#ff69b4', width: '8px',
          height: '8px', left: '70%'
        }}
      />


      <div>
        <div style={{ color: 'black', fontSize: 12, textAlign: 'center', marginBottom: "4px", marginTop:"4px" }}>{data.label}</div>
        {data.taskType === "parallel-state" ?
          data.parallel.map((x: PublishedFSM, index: number) => {
            return (
              <div style={customNodeChildStyles}>
                <div style={{ color: 'black', fontSize: 11 }}>{x.name}</div>
              </div>)
          }) : null
        }
      </div>
    </div>
  );
};
interface PublishedFSM {
  name: string,
  url: string
}
const nodeTypes = {
  special: CustomSpecialNodeComponent,
};

const onDragOver = (event: DragEvent) => {
  event.preventDefault();
  event.dataTransfer.dropEffect = 'move';
};
const getId = (): ElementId => `dndnode_${uuid_v4()}`;

const LiveFlow = (props: any) => {
  const [chkbox, setChkBox] = useState<boolean>(false);
  const [toNodeDisplay, setToNodeDisplay] = useState<boolean>(false);
  const [nodeFormData, setNodeFormData] = useState({ label: "", id: "", retry: "", url: "" })
  const [edgeFormData, setEdgeFormData] = useState({ label: "", id: "", condition: "" })
  const [openElementId, setOpenElementId] = React.useState('');

  const handleCloseState = () => {
    setToNodeDisplay(false);
  }
  useEffect(
    () => {
      setNodeFormData(nodeFormData);
      setEdgeFormData(edgeFormData);
    }, [nodeFormData, edgeFormData]
  )
  const onElementClick = (event: any, element: any) => {
    setToNodeDisplay(false);
    if (isNode(element)) {
      setOpenElementId(element['id']);
      setNodeFormData({ label: element.data.label, id: element.id, retry: element.data.retry, url: element.data.url })
      setEdgeFormData({ label: "", id: "", condition: "" })
      setToNodeDisplay(true);
    }

  };



  const [reactFlowInstance, setReactFlowInstance] = useState<OnLoadParams>();
  const [elements, setElements] = useState<Elements>(initialElements);

  const [userFlowState, setUserFlowState] = useState([[]]);
  useEffect(() => {
    getData();
    async function getData() {
      let response : any;
      try {
        response = await axios.get("executions/" + props.match.params.execution_id + "/fetch_fsm_data/");
        const data = ((await response).data)
        if (data) {
          setElements(data.elements || []);
        }
        populateTraceForVisualisation(data);
      } catch (error: any) {
        console.log(error.message)
      }

    }
  }, []);

  useEffect(() => { }, userFlowState);

  const onLoad = (_reactFlowInstance: OnLoadParams) => setReactFlowInstance(_reactFlowInstance);
  const [isDraggable, setIsDraggable] = useState(false);
  const [isConnectable, setIsConnectable] = useState(false);
  const [traceForVisualisation, setTraceForVisualisation] = useState(new Map());
  const [stepSequence, setStepSequence] = useState(new Map());

  const populateTraceForVisualisation = (data: any) => {
    return getData();
    async function getData() {
      const jsonData = data["elements"];
      console.log(jsonData);
      var nodeIdVsTraceArray = new Map();
      var nodeIdVsStepNumber = new Map();
      jsonData.forEach((element: any) => {
        if (element.hasOwnProperty('source'))
          return;
        if (!nodeIdVsTraceArray.hasOwnProperty(element['id'])) {
          nodeIdVsStepNumber.set(element['id'], []);
          nodeIdVsTraceArray.set(element['id'], []);
        }
        if (element['id'] === '1' && element['data'].hasOwnProperty('state')) {
          var json = {
            "input": element['data']['state']['0']['input'],
            "output": element['data']['state']['0']['output']
          };
          nodeIdVsStepNumber.set(element['id'], ["Start"]);
          nodeIdVsTraceArray.set(element['id'], [json]);
        }
        else if (element['id'] === '2' && element['data'].hasOwnProperty('state')) {
          var json = {
            "input": element['data']['state']['0']['input'],
            "output": element['data']['state']['0']['output']
          };
          nodeIdVsStepNumber.set(element['id'], ["End"]);
          nodeIdVsTraceArray.set(element['id'], [json]);
        }
        else if (element['data'].hasOwnProperty('state')) {
          console.log(element['id']);
          const stepElement = element['data']['state'];
          for (let key in stepElement) {
            const value = stepElement[key];
            var json = {
              "input": value['input'],
              "output": value['output']
            };
            nodeIdVsStepNumber.get(element['id']).push(Number(key) + 1);
            nodeIdVsTraceArray.get(element['id']).push(json);
          };
        }
      });
      console.log(nodeIdVsStepNumber);
      console.log(nodeIdVsTraceArray);
      setTraceForVisualisation(nodeIdVsTraceArray);
      setStepSequence(nodeIdVsStepNumber);
    }
  }

  return (
    <div className="flow">
      <Sidebar />
      <ReactFlowProvider>
        <div className="reactflow-wrapper">
          <ReactFlow
            elements={elements}
            // onConnect={onConnect}
            // onElementsRemove={onElementsRemove}
            onLoad={onLoad}
            // onDrop={onDrop}
            // onDragOver={onDragOver}
            nodesConnectable={isConnectable}
            nodesDraggable={isDraggable}
            onElementClick={onElementClick}
            nodeTypes={nodeTypes}
            edgeTypes={{ transition: TransitionEdge }}
          >
            <Background variant={BackgroundVariant.Lines} />
            <Background variant={BackgroundVariant.Lines} />
            <div style={{ position: 'absolute', left: 10, top: 10, zIndex: 4 }}>
            </div>
            <div style={{ position: 'absolute', right: 10, top: 10, zIndex: 4 }}>

            </div>
            <Controls />
          </ReactFlow>
        </div>
      </ReactFlowProvider>
      <Dialog open={toNodeDisplay} onClose={handleCloseState}>
        <DialogTitle> State Input and Output</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Execution Order
          </DialogContentText>
        </DialogContent>
        <CustomizedAccordions accordionData={traceForVisualisation.get(openElementId)} stepSequence={stepSequence.get(openElementId)} liveFlow={true} />
      </Dialog>
    </div>
  );
};




export default LiveFlow;
