import React, { useReducer, useEffect} from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import axios from "axios";
import {backendURL, frontendURL} from "./axios";
import {uuid} from 'uuidv4';
import './css/DevconnectButton.css'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      width: 400,
      margin: `${theme.spacing(0)} auto`
    },
    loginBtn: {
      marginTop: theme.spacing(2),
      flexGrow: 1
    },
    header: {
      textAlign: 'center',
      background: '#212121',
      color: '#fff'
    },
    card: {
      marginTop: theme.spacing(10)
    }
  })
);

//state type

type State = {
  username: string
  password: string
  isButtonDisabled: boolean
  helperText: string
  isError: boolean
};

const initialState: State = {
  username: '',
  password: '',
  isButtonDisabled: true,
  helperText: '',
  isError: false
};

type Action = { type: 'setUsername', payload: string }
  | { type: 'setPassword', payload: string }
  | { type: 'setIsButtonDisabled', payload: boolean }
  | { type: 'loginSuccess', payload: string }
  | { type: 'loginFailed', payload: string }
  | { type: 'setIsError', payload: boolean };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'setUsername':
      return {
        ...state,
        username: action.payload
      };
    case 'setPassword':
      return {
        ...state,
        password: action.payload
      };
    case 'setIsButtonDisabled':
      return {
        ...state,
        isButtonDisabled: action.payload
      };
    case 'loginSuccess':
      return {
        ...state,
        helperText: action.payload,
        isError: false
      };
    case 'loginFailed':
      return {
        ...state,
        helperText: action.payload,
        isError: true
      };
    case 'setIsError':
      return {
        ...state,
        isError: action.payload
      };
  }
}





const Login = () => {
  const classes = useStyles();
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    if (state.username.trim() && state.password.trim()) {
      dispatch({
        type: 'setIsButtonDisabled',
        payload: false
      });
    } else {
      dispatch({
        type: 'setIsButtonDisabled',
        payload: true
      });
    }
  }, [state.username, state.password]);
  const handleLogin = async () => {
    try {
      const res = await axios.post(
        backendURL+"auth/login/",
          {
              username: state.username,
              password: state.password,
          }
      );
          localStorage.setItem("username", state.username);
          localStorage.setItem("token", res.data.token);
          window.location.assign("/user/dashboard");
        } catch (err) {
            alert('Incorrect Username or Password')
        }
  };
  const handleRegister = async () => {
    try {
      console.log(backendURL)
        await axios.post(
          backendURL+"users/",
            {
                username: state.username,
                password: state.password,
            }
        );
            alert("Succesfully created a new user");
        } catch (err) {
            alert('Username should be unique. 150 characters or fewer. Letters, digits and @/./+/-/_ only')
        }
  };
  

  const handleUsernameChange: React.ChangeEventHandler<HTMLInputElement> =
    (event) => {
      dispatch({
        type: 'setUsername',
        payload: event.target.value
      });
    };

  const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> =
    (event) => {
      dispatch({
        type: 'setPassword',
        payload: event.target.value
      });
    }

  function getDevConnectUrl() {
    let client_id = 'tu2k21';
    // let redirect_uri = encodeURIComponent('https://fsm-workflow.tu2k21.devfactory.com/auth');   // this will change
    let redirect_uri = encodeURIComponent(frontendURL+ 'auth'); 
    let state = uuid();
    let nonce = uuid();
    return `https://devfactory.devconnect-df.com/auth/realms/devfactory/protocol/openid-connect/auth?client_id=${client_id}&redirect_uri=${redirect_uri}&state=${state}&response_mode=fragment&response_type=code&scope=openid&nonce=${nonce}`
  }

  return (
    
    <form className={classes.container} noValidate autoComplete="off">
      <Card className={classes.card}>
        <CardHeader className={classes.header} title="Login App" />
        <CardContent>
          <div>
            <TextField
              error={state.isError}
              fullWidth
              id="username"
              type="email"
              label="Username"
              placeholder="Username"
              margin="normal"
              onChange={handleUsernameChange}
            />
            <TextField
              error={state.isError}
              fullWidth
              id="password"
              type="password"
              label="Password"
              placeholder="Password"
              margin="normal"
              helperText={state.helperText}
              onChange={handlePasswordChange}
            />
          </div>
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            className={classes.loginBtn}
            onClick={handleLogin}
            disabled={state.isButtonDisabled}>
            Login
          </Button>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            className={classes.loginBtn}
            onClick={handleRegister}
            disabled={state.isButtonDisabled}>
            Register
          </Button>
          <Button variant="contained"
            size="large"
            color="secondary" className="DevconnectButton" onClick={() => {
            window.location.href = getDevConnectUrl();
          }}>DevConnect</Button>
        </CardActions>
      </Card>
    </form>
  );
}



export default Login;