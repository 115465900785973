import { Button, createStyles, makeStyles, TextField } from '@material-ui/core';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Snackbar from '@mui/material/Snackbar';
import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import axios from '../../axios';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import GridContainer from '../../components/Grid/GridContainer';
// core components
import GridItem from '../../components/Grid/GridItem';
import Table from '../../components/Table/Table';

const styles = createStyles({
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0'
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF'
    }
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: 300,
    fontFamily: '\'Roboto\', \'Helvetica\', \'Arial\', sans-serif',
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: 400,
      lineHeight: 1
    }
  }
});




interface IFormInput {
  [key: string]: string;
  workspaceName: string;

}
function TableList(props: any) {
  const onSubmit = (data: IFormInput) => {
    setJson(JSON.stringify(data));
    console.log(JSON.stringify(data));
    createWorkspace(data);
  };

  const schema = yup.object().shape({
    workspaceName: yup.string().required().min(1).max(25),
  });

  const useStyles = makeStyles((theme) => ({
    heading: {
      textAlign: "center",
      margin: theme.spacing(1, 0, 4),
    },
    submitButton: {
      marginTop: theme.spacing(4),
    },
  }));

  const [alertIsActive, setAlertIsActive] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertTitle, setAlertTitle] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<any>('');

  const setAlertStatus = (title: string, content: string, severity: string, is_active: boolean) => {
    setAlertTitle(title);
    setAlertContent(content);
    setAlertSeverity(severity);
    setAlertIsActive(is_active);
  }
  enum AlertSeverityEnum {
    Success = "success",
    Warning = "warning",
    Info = "info",
    Error = "error",
  }
  enum AlertTitleEnum {
    Success = "Success!",
    Warning = "Warning!",
    Info = "Info!",
    Error = "Error!",
  }

  const createWorkspace = async (data: IFormInput) => {
    const body = {
      name: data["workspaceName"]
    };
    axios.post(
      "workspaces/",
      body
    ).then(data => {
      const alertContent = "Workspace created!";
      setAlertStatus(AlertTitleEnum.Success, alertContent, AlertSeverityEnum.Success, true);
      window.location.assign("/user/workspaces/" + data.data.id);  // to be updated -> reloading after a few seconds maybe?
    }).catch(err => {
      alert('Invalid workspace name!')      // to be updated
    })
  };


  const { heading, submitButton } = useStyles();
  const [json, setJson] = useState<string>();
  const {
    register,
    handleSubmit,
  } = useForm<IFormInput>();

  const [userWorkspaces, setUserWorkspaces] = useState([[]]);
  useEffect(() => {
    getData();
    async function getData() {
      let response: any;
      try {
        response = await axios.get("workspaces/");
        const jsonData = response["data"];
        var arrayData = new Array();
        for (let jsonElement of jsonData) {
          var elementArray = new Array();
          Object.entries(jsonElement).forEach(([key, value]) => {
            if (key !== "members")
              elementArray.push(String(value));
          });
          arrayData.push(elementArray);
        }
        setUserWorkspaces(arrayData);
      } catch (err: any) {
        console.log(err.message);
      }
    }

  }, []);

  useEffect(() => { }, userWorkspaces);

  const cleanAlertState = () => {
    setAlertIsActive(false);
    setAlertTitle('');
    setAlertContent('');
    setAlertSeverity('');
  }

  const { classes } = props;
  return (
    <GridContainer>
      {alertIsActive ?
        <Snackbar open={alertIsActive} autoHideDuration={6000} onClose={cleanAlertState} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
          <Alert variant="filled" severity={alertSeverity} onClose={cleanAlertState}>
            <AlertTitle>{alertTitle}</AlertTitle>
            <strong>{alertContent}</strong>
          </Alert>
        </Snackbar> : <></>
      }
      <GridItem xs={12} sm={12} md={8}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>Workspaces</h4>
            <p className={classes.cardCategoryWhite}>
              Active Workspaces
            </p>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor=""
              tableHead={['Workspace Name', 'Last Updated At', 'Click to open!']}
              tableData={userWorkspaces}
              redirectUrl="/user/workspaces/"
              addMember={true}
            />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={3}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>New workspace</h4>
            <p className={classes.cardCategoryWhite}>
              Create New Workspace
            </p>
          </CardHeader>
          <CardBody>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <TextField
                {...register("workspaceName")}
                variant="outlined"
                margin="normal"
                label="Workspace Name"
                size="small"
                fullWidth
                required
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={submitButton}
              >
                Create
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

export default withStyles(styles)(TableList);
