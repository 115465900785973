import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import React from 'react';
// import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
// core components
import sidebarStyle from '../../assets/jss/material-dashboard-react/components/sidebarStyle';
import axios from '../../axios';
import './Sidebar.css';

const Logout = () => {
  axios.post('auth/logout/').catch(err => console.log(err.message));
  localStorage.clear()
  window.location.assign("/");

}

const Sidebar = ({ ...props }) => {
  // verifies if routeName is the one active (in browser input)
  const goBack = () => {
    props.history.goBack();
  }
  function activeRoute(routeName: any) {
    return props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }
  const goToHomepage = () => {
    window.location.assign("/user/dashboard");
  }

  const { classes, color, logo, image, logoText, routes } = props;
  var links = (
    <List className={classes.list}>

      {routes.map((prop: any, key: any) => {
        var activePro = ' ';
        var listItemClasses;

        listItemClasses = classNames({
          [' ' + classes[color]]: activeRoute(prop.layout + prop.path)
        });

        const whiteFontClasses = classNames({
          [' ' + classes.whiteFont]: activeRoute(prop.layout + prop.path)
        });
        return (
          <NavLink
            to={prop.layout + prop.path}
            className={activePro + classes.item}
            activeClassName="active"
            key={key}
          >
            <ListItem button={true} className={classes.itemLink + listItemClasses}>
              {typeof prop.icon === 'string' ? (
                <Icon
                  className={classNames(classes.itemIcon, whiteFontClasses, {
                    [classes.itemIconRTL]: props.rtlActive
                  })}
                >
                  {prop.icon}
                </Icon>
              ) : (
                <prop.icon
                  className={classNames(classes.itemIcon, whiteFontClasses, {
                    [classes.itemIconRTL]: props.rtlActive
                  })}
                />
              )}
              <ListItemText
                primary={
                  props.rtlActive ? prop.rtlName : prop.name
                }
                className={classNames(classes.itemText, whiteFontClasses, {
                  [classes.itemTextRTL]: props.rtlActive
                })}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>
        );
      })}

      <div className="logout">
        <button className="button-1" role="button" onClick={goBack}>Back</button>
        <br></br>
        <br></br>
        {/* <br></br> */}
        <br></br>
        <button className="button-66" role="button" onClick={Logout}>Logout</button>

      </div>
      {/* <div className={classes.logo}>
       
      </div> */}
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      <a onClick={goToHomepage} className={classNames(classes.logoLink, {
        [classes.logoLinkRTL]: props.rtlActive
      })}
      >
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
        {logoText}
      </a>
    </div>
  );
  return (
    <div>
      <Hidden mdUp={true} implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? 'left' : 'right'}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: 'url(' + image + ')' }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown={true} implementation="css">
        <Drawer
          anchor={props.rtlActive ? 'right' : 'left'}
          variant="permanent"
          open={true}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: 'url(' + image + ')' }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
};

// Sidebar.propTypes = {
//   classes: PropTypes.object.isRequired
// };

export default withStyles(sidebarStyle)(Sidebar);
