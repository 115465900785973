import { Button, createStyles, makeStyles, TextField } from '@material-ui/core';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Snackbar from '@mui/material/Snackbar';
import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import axios from '../../axios';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import GridContainer from '../../components/Grid/GridContainer';
// core components
import GridItem from '../../components/Grid/GridItem';
import Table from '../../components/Table/Table';

const styles = createStyles({
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0'
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF'
    }
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: 300,
    fontFamily: '\'Roboto\', \'Helvetica\', \'Arial\', sans-serif',
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: 400,
      lineHeight: 1
    }
  }
});



const initialElements = "{\"elements\":[{\"id\":\"1\",\"type\":\"input\",\"data\":{\"label\":\"Start\"},\"position\":{\"x\":250,\"y\":250},\"sourcePosition\":\"right\",\"targetPosition\":\"left\"},{\"id\":\"2\",\"type\":\"output\",\"data\":{\"label\":\"End\"},\"position\":{\"x\":750,\"y\":250},\"sourcePosition\":\"right\",\"targetPosition\":\"left\"}],\"position\":[0,0],\"zoom\":1}"

interface IFormInput {
  [key: string]: string;
  flowName: string;
  userName: string;
}
function FlowList(props: any) {

  enum AlertSeverityEnum {
    Success = "success",
    Warning = "warning",
    Info = "info",
    Error = "error",
  }
  enum AlertTitleEnum {
    Success = "Success!",
    Warning = "Warning!",
    Info = "Info!",
    Error = "Error!",
  }

  const [alertIsActive, setAlertIsActive] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertTitle, setAlertTitle] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<any>('');

  const setAlertStatus = (title: string, content: string, severity: string, is_active: boolean) => {
    setAlertTitle(title);
    setAlertContent(content);
    setAlertSeverity(severity);
    setAlertIsActive(is_active);
  }

  const onSubmitCreateFlow = (data: IFormInput) => {
    setJson(JSON.stringify(data));
    console.log(JSON.stringify(data));
    createFlow(data);
  };

  const onSubmitActions = (data: IFormInput) => {
    setJson(JSON.stringify(data));
    console.log(JSON.stringify(data));
    addAction(data);
  };

  const addAction = async (data: IFormInput) => {
    const body = {
      name: data["actionName"],
      url: data["actionURL"],
      workspace_id: props.match.params.workspace_id
    };
    axios.post(
      "devflows/",
      body
    ).then(data => {
      const alertContent = "Action added successfully!";
      setAlertStatus(AlertTitleEnum.Success, alertContent, AlertSeverityEnum.Success, true);
      window.location.assign("/user/workspaces/" + props.match.params.workspace_id);
    }).catch(err => {
      console.log(err);
      setAlertStatus(AlertTitleEnum.Error, "Something went wrong!", AlertSeverityEnum.Error, true);
    })
  };

  const onSubmitAddMember = (data: IFormInput) => {
    setJson(JSON.stringify(data));
    console.log(JSON.stringify(data));
    addMember(data);
  };

  const schema = yup.object().shape({
    flowName: yup.string().required().min(1).max(25),
  });

  const useStyles = makeStyles((theme) => ({
    heading: {
      textAlign: "center",
      margin: theme.spacing(1, 0, 4),
    },
    submitButton: {
      marginTop: theme.spacing(4),
    },
  }));

  const addMember = async (data: IFormInput) => {
    const body = {
      username: data["userName"],
    };
    axios.put(
      "workspaces/" + props.match.params.workspace_id + "/members/",
      body
    ).then(data => {
      const alertContent = "Member added successfully!";
      setAlertStatus(AlertTitleEnum.Success, alertContent, AlertSeverityEnum.Success, true);
      window.location.assign("/user/workspaces/" + props.match.params.workspace_id);
    }).catch(error => {
      console.log(error);
      setAlertStatus(AlertTitleEnum.Error, "Something went wrong!", AlertSeverityEnum.Error, true);
    })
  };

  const createFlow = async (data: IFormInput) => {
    const body = {
      name: data["flowName"],
      workspace_id: props.match.params.workspace_id,
      fsm: initialElements,
      is_active: true
    };
    axios.post(
      "flows/",
      body
    ).then(data => {
      const alertContent = "Flow created successfully!";
      setAlertStatus(AlertTitleEnum.Success, alertContent, AlertSeverityEnum.Success, true);
      window.location.assign("/user/flows/" + data.data.id);
    }).catch(err => {
      console.log(err);
      setAlertStatus(AlertTitleEnum.Error, "Something went wrong!", AlertSeverityEnum.Error, true);
    })
  };


  const { heading, submitButton } = useStyles();
  const [json, setJson] = useState<string>();
  const {
    register,
    handleSubmit,
  } = useForm<IFormInput>();

  const [userWorkspacesFlows, setUserWorkspacesFlows] = useState([[]]);
  useEffect(() => {
    getData();
    async function getData() {
      let response: any;
      try {
        response = await axios.get("workspaces/" + props.match.params.workspace_id + "/flows/");
        let jsonData = response["data"];
        var arrayData = new Array();
        for (let jsonElement of jsonData) {
          var elementArray = new Array();
          elementArray.push(jsonElement["pk"]);
          elementArray.push(jsonElement["fields"]["name"]);
          elementArray.push(jsonElement["fields"]["updated_at"]);
          arrayData.push(elementArray);
        }
        setUserWorkspacesFlows(arrayData);
        console.log(arrayData);
      } catch (err: any) {
        console.log(err.message);
      }

    }

  }, []);

  useEffect(() => { }, userWorkspacesFlows);
  const cleanAlertState = () => {
    setAlertIsActive(false);
    setAlertTitle('');
    setAlertContent('');
    setAlertSeverity('');
  }
  const { classes } = props;
  return (
    <GridContainer sx={{ width: 1 / 4 }}>
      {alertIsActive ?
        <Snackbar open={alertIsActive} autoHideDuration={6000} onClose={cleanAlertState} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
          <Alert variant="filled" severity={alertSeverity} onClose={cleanAlertState}>
            <AlertTitle>{alertTitle}</AlertTitle>
            <strong>{alertContent}</strong>
          </Alert>
        </Snackbar> : <></>
      }
      <GridItem xs={12} sm={12} md={8}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>Flows</h4>
            <p className={classes.cardCategoryWhite}>
              Flow List
            </p>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor=""
              tableHead={['Flow Name', 'Last Updated At', 'Click to open!']}
              tableData={userWorkspacesFlows}
              redirectUrl="/user/flows/"
            />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={3}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>New Flow</h4>
            <p className={classes.cardCategoryWhite}>
              Create New Flow
            </p>
          </CardHeader>
          <CardBody>
            <form onSubmit={handleSubmit(onSubmitCreateFlow)} noValidate>
              <TextField
                {...register("flowName")}
                variant="outlined"
                margin="normal"
                label="Flow Name"
                fullWidth
                required
                size="small"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={submitButton}
              >
                Create
              </Button>
            </form>
          </CardBody>
        </Card>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>Add member</h4>
            <p className={classes.cardCategoryWhite}>
              Add new member to the workspace
            </p>
          </CardHeader>
          <CardBody>
            <form onSubmit={handleSubmit(onSubmitAddMember)} noValidate>
              <TextField
                {...register("userName")}
                variant="outlined"
                margin="normal"
                label="username"
                fullWidth
                required
                size="small"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={submitButton}
              >
                Add
              </Button>
            </form>
          </CardBody>
        </Card>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>Add Action</h4>
            <p className={classes.cardCategoryWhite}>
              Add action to the workspace
            </p>
          </CardHeader>
          <CardBody>
            <form onSubmit={handleSubmit(onSubmitActions)} noValidate>
              <TextField
                {...register("actionName")}
                variant="outlined"
                margin="normal"
                label="Action Name"
                fullWidth
                required
                size="small"
              />
              <TextField
                {...register("actionURL")}
                variant="outlined"
                margin="normal"
                label="Action URL"
                type="url"
                fullWidth
                required
                size="small"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={submitButton}
              >
                Add
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

export default withStyles(styles)(FlowList);
