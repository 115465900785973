import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { createTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { createStyles, makeStyles } from '@mui/styles';
import {
    DataGrid, GridApi, GridCellValue, GridColDef, GridToolbarDensitySelector,
    GridToolbarFilterButton, GridOverlay, GridToolbar
} from '@mui/x-data-grid';
import * as React from 'react';
function escapeRegExp(value: string): string {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}


const defaultTheme = createTheme();
const useStyles = makeStyles(
    (theme) =>
        createStyles({
            root: {
                padding: theme.spacing(0.5, 0.5, 0),
                justifyContent: 'space-between',
                display: 'flex',
                alignItems: 'flex-start',
                flexWrap: 'wrap',
            },
            textField: {
                [theme.breakpoints.down('xs')]: {
                    width: '100%',
                },
                margin: theme.spacing(1, 0.5, 1.5),
                '& .MuiSvgIcon-root': {
                    marginRight: theme.spacing(0.5),
                },
                '& .MuiInput-underline:before': {
                    borderBottom: `1px solid ${theme.palette.divider}`,
                },
            },
        }),
    { defaultTheme },
);

interface QuickSearchToolbarProps {
    clearSearch: () => void;
    onChange: () => void;
    value: string;
}

function QuickSearchToolbar(props: QuickSearchToolbarProps) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
            </div>
            <TextField
                variant="standard"
                value={props.value}
                onChange={props.onChange}
                placeholder="Search…"
                className={classes.textField}
                InputProps={{
                    startAdornment: <SearchIcon fontSize="small" />,
                    endAdornment: (
                        <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{ visibility: props.value ? 'visible' : 'hidden' }}
                            onClick={props.clearSearch}
                        >
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    ),
                }}
            />
        </div>
    );
}

export default function QuickFilteringGrid({ ...props }: any) {
    const { classes, tableHead, tableData, tableHeaderColor, redirectUrl, showFirstIndex = false, addButton = true, addId = true, addVisualisationButton = false, visualisationPageUrl } = props;
    const [searchText, setSearchText] = React.useState('');
    const [rows, setRows] = React.useState<any[]>([]);

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'Execution Id', width: 140 },
        {
            field: 'execution_trace_id',
            headerName: 'Execution Trace Id',
            width: 350,
        },
        {
            field: 'start_date',
            headerName: 'Start Date',
            width: 220,
        },
        {
            field: 'current_state',
            headerName: 'Current State',
            width: 300,
        },
        {
            field: 'Visualisation',
            headerName: 'Visualisation',
            sortable: false,
            width: 150,

            renderCell: (params) => {
                const onClick = (e: any) => {

                    const api: GridApi = params.api;
                    const thisRow: Record<string, GridCellValue> = {};

                    api
                        .getAllColumns()
                        .filter((c) => c.field !== '__check__' && !!c)
                        .forEach(
                            (c) => (thisRow[c.field] = params.getValue(params.id, c.field)),
                        );
                    window.location.assign(redirectUrl + thisRow['id'] + "/live");
                };

                return <Button variant="contained" color="info" onClick={onClick}>Open</Button>;
            }
        },
        {
            field: 'execution_details',
            headerName: 'Execution Details',
            sortable: false,
            width: 150,
            renderCell: (params) => {
                const onClick = (e: any) => {

                    const api: GridApi = params.api;
                    const thisRow: Record<string, GridCellValue> = {};

                    api
                        .getAllColumns()
                        .filter((c) => c.field !== '__check__' && !!c)
                        .forEach(
                            (c) => (thisRow[c.field] = params.getValue(params.id, c.field)),
                        );

                    window.location.assign(redirectUrl + thisRow['id']);
                };

                return <Button style={{display:'flex', alignItems:'center', justifyContent:'center'}} variant="contained" color="info" onClick={onClick}>Open</Button>;
            }
        },
    ];

    const requestSearch = (searchValue: string) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = rows.filter((row: any) => {
            return Object.keys(row).some((field: any) => {
                return searchRegex.test(row[field]? row[field].toString(): '');
            });
        });
        setRows(filteredRows);
    };

    React.useEffect(() => {
        setRows(tableData);
    }, [tableData]);
    function CustomNoRowsOverlay() {
        const classes = useStyles();

        return (
            <GridOverlay className={classes.root}>
                <svg
                    width="120"
                    height="100"
                    viewBox="0 0 184 152"
                    aria-hidden
                    focusable="false"
                >
                    <g fill="none" fillRule="evenodd">
                        <g transform="translate(24 31.67)">
                            <ellipse
                                className="ant-empty-img-5"
                                cx="67.797"
                                cy="106.89"
                                rx="67.797"
                                ry="12.668"
                            />
                            <path
                                className="ant-empty-img-1"
                                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                            />
                            <path
                                className="ant-empty-img-2"
                                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                            />
                            <path
                                className="ant-empty-img-3"
                                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                            />
                        </g>
                        <path
                            className="ant-empty-img-3"
                            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                        />
                        <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                        </g>
                    </g>
                </svg>
                <div>No Rows</div>
            </GridOverlay>
        );
    }
    const [page, setPage] = React.useState(0);

    return (
        <div style={{ height: 400, width: '100%' }}>
            <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataGrid
                        getRowId={(row) => row.id}
                        page={page}
                        onPageChange={(newPage) => setPage(newPage)}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        pagination
                        components={{ Toolbar: GridToolbar, NoRowsOverlay: CustomNoRowsOverlay }}
                        rows={rows}
                        columns={columns}
                        componentsProps={{
                            toolbar: {
                                value: searchText,
                                onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                                    requestSearch(event.target.value),
                                clearSearch: () => requestSearch(''),
                            },
                        }}
                    />
                </div>
            </div>
        </div>
    );
}