import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./css/App.css";
import User from './layouts/User';
import Login from './Login';
import Auth from './components/Auth/Auth';
import Flow from './flow';
import LiveFlow from './components/LiveFLow/LiveFlow';

const App = () => {
  return (
      <Router>
        <Switch>
          <Route path="/user/flows/:flow_id" component={Flow} exact />
          <Route path="/auth" component={Auth} exact />
          <Route path="/" component={Login} exact />
          <Route path="/user/flows/:flow_id/execution/:execution_id/live" component={LiveFlow} exact/>
          <Route path="/user" component={User}/>
        </Switch>
      </Router>
  );
}
export default App;
