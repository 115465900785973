import axios from "axios";

// export const backendURL = "https://fsm-workflow.stage2.cnu-tu.ey.io/api/v1/"
// export const frontendURL = "https://fsm-workflow.tu2k21.devfactory.com/"
// export const devflowsUrl = 'https://df.rp.devfactory.com/';

export const backendURL = process.env.REACT_APP_BACKEND_URL
export const frontendURL = process.env.REACT_APP_FRONTEND_URL
export const devflowsUrl = process.env.REACT_APP_DEVFLOW_URL

const instance = axios.create({
  baseURL: backendURL,
  headers: {
    Authorization: 'Token ' + localStorage.getItem('token'),
  }
});

export default instance;
